export const config = {
  websiteUrl: process.env.NEXT_PUBLIC_WEBSITE_URL || 'https://localhost:3000',
  websiteName: 'Mintum',
  websiteDescription: 'Mintum.is leyfir þér að breyta krónum í rafkrónur',
  apiUrl: process.env.NEXT_PUBLIC_API_URL || 'http://localhost:4000',
  googleTrackingId: process.env.NEXT_PUBLIC_GA_ID,
  facebookPixelId: process.env.NEXT_PUBLIC_FACEBOOK_PIXEL_ID,
  gistAppId: process.env.NEXT_PUBLIC_GIST_APP_ID,
  sentryDsn: process.env.NEXT_PUBLIC_SENTRY_DSN,
  isDevelopment: process.env.NODE_ENV !== 'production',
};
