import { useId } from '@mantine/hooks';
import { useCallback, useEffect, useRef, useState } from 'react';

export function MintumLogo() {
  const animationDuration = `2s`;
  const repeatCount = '1';
  const offset = 0.25;
  const range = 1.0;
  const getOffset = (x: number) => `${x * offset}; ${x * offset + range}`;
  const getColor = (x: number, n: number, c: string) =>
    [
      ...Array.from({ length: x }).fill(c),
      c,
      ...Array.from({ length: n - x - 1 }).fill('currentColor'),
    ].join(';');
  const ref = useRef<SVGSVGElement>(null);
  const [mouseOver, setMouseOver] = useState(false);
  const [animating, setAnimating] = useState(false);
  const id = useId();

  useEffect(() => {
    ref.current?.addEventListener('mouseover', () => setMouseOver(true));
    ref.current?.addEventListener('mouseout', () => setMouseOver(false));
  }, []);

  const animate = useCallback(() => {
    if (ref.current) {
      const animatedElements =
        ref.current.querySelectorAll<SVGAnimateElement>('animate');
      if (!animating) {
        setAnimating(true);
        animatedElements.forEach((el) => el.beginElement());
      }
    }
  }, [animating]);

  useEffect(() => {
    if (ref.current) {
      const animatedElements =
        ref.current.querySelectorAll<SVGAnimateElement>('animate');
      const first = animatedElements[0];
      first.addEventListener('endEvent', () => {
        setAnimating(false);
      });
    }
  }, [ref]);

  useEffect(() => {
    if (mouseOver) {
      animate();
    }
  }, [mouseOver, animate]);

  return (
    <svg
      width="160"
      height="36"
      viewBox="0 0 160 36"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className="logo"
      ref={ref}>
      <defs>
        <linearGradient id={`grad-${id}`} x1="0" x2="1" y1="0" y2="0">
          <stop offset="0%" stopColor="currentColor" className="stop1">
            <animate
              attributeName="offset"
              values={getOffset(0)}
              dur={animationDuration}
              repeatCount={repeatCount}
            />
            <animate
              attributeName="stop-color"
              values={getColor(0, 4, '#db09e8')}
              dur={animationDuration}
              repeatCount={repeatCount}
            />
          </stop>
          <stop offset="25%" stopColor="#00D0D8" className="stop2">
            <animate
              attributeName="offset"
              values={getOffset(1)}
              dur={animationDuration}
              repeatCount={repeatCount}
            />
            <animate
              attributeName="stop-color"
              values={getColor(1, 4, '#00D0D8')}
              dur={animationDuration}
              repeatCount={repeatCount}
            />
          </stop>
          <stop offset="75%" stopColor="#3E45FF" className="stop3">
            <animate
              attributeName="offset"
              values={getOffset(2)}
              dur={animationDuration}
              repeatCount={repeatCount}
            />
            <animate
              attributeName="stop-color"
              values={getColor(2, 4, '#3E45FF')}
              dur={animationDuration}
            />
          </stop>
          <stop offset="100%" stopColor="#C611EB" className="stop4">
            <animate
              attributeName="offset"
              values={getOffset(3)}
              dur={animationDuration}
              repeatCount={repeatCount}
            />
            <animate
              attributeName="stop-color"
              values={getColor(3, 4, '#C611EB')}
              dur={animationDuration}
              repeatCount={repeatCount}
            />
          </stop>
        </linearGradient>
        <mask id="foo">
          <path
            d="M0.887528 26.7254C0.459743 27.8921 1.05874 29.1847 2.22543 29.6125C3.39211 30.0403 4.68469 29.4413 5.11247 28.2746L0.887528 26.7254ZM31.9348 19.3931C32.428 20.5336 33.7525 21.0584 34.8931 20.5652C36.0336 20.072 36.5584 18.7475 36.0652 17.6069L31.9348 19.3931ZM10.492 17.0816L8.42861 17.9788L10.492 17.0816ZM5.11247 28.2746L8.89259 17.9652L4.66765 16.416L0.887528 26.7254L5.11247 28.2746ZM8.42861 17.9788L13.9399 30.6548L18.0668 28.8606L12.5554 16.1845L8.42861 17.9788ZM21.9957 29.8387L26.9027 6.6124L22.4999 5.68222L17.5929 28.9086L21.9957 29.8387ZM26.4286 6.65995L31.9348 19.3931L36.0652 17.6069L30.559 4.87385L26.4286 6.65995ZM26.9027 6.6124C26.9027 6.61232 26.8976 6.63399 26.8782 6.66644C26.8592 6.69822 26.8339 6.72756 26.8059 6.75123C26.7524 6.79636 26.7069 6.80708 26.683 6.80947C26.6591 6.81187 26.6124 6.8104 26.5511 6.77679C26.5189 6.75917 26.4883 6.73542 26.4633 6.70805C26.4378 6.68011 26.4286 6.65987 26.4286 6.65995L30.559 4.87385C28.9146 1.07114 23.3562 1.62867 22.4999 5.68222L26.9027 6.6124ZM13.9399 30.6548C15.5902 34.4504 21.1402 33.8881 21.9957 29.8387L17.5929 28.9086C17.5927 28.9096 17.5938 28.9041 17.5978 28.8937C17.6019 28.8832 17.6082 28.8698 17.6174 28.8545C17.6364 28.8228 17.6615 28.7935 17.6895 28.7698C17.7429 28.7247 17.7883 28.714 17.8123 28.7115C17.8362 28.7091 17.8829 28.7105 17.9443 28.744C17.9764 28.7616 18.007 28.7852 18.0319 28.8125C18.044 28.8256 18.0528 28.8376 18.0589 28.847C18.065 28.8564 18.0672 28.8615 18.0668 28.8606L13.9399 30.6548ZM8.89259 17.9652C8.88465 17.9868 8.85165 18.0419 8.7892 18.0845C8.7376 18.1197 8.69339 18.1282 8.66521 18.129C8.63703 18.1298 8.5924 18.1239 8.53882 18.0918C8.47398 18.0529 8.43781 17.9999 8.42861 17.9788L12.5554 16.1845C11.0262 12.6672 5.98799 12.8151 4.66765 16.416L8.89259 17.9652Z"
            fill="white"
          />
        </mask>
      </defs>
      <path
        id="mark"
        d="M0.887528 26.7254C0.459743 27.8921 1.05874 29.1847 2.22543 29.6125C3.39211 30.0403 4.68469 29.4413 5.11247 28.2746L0.887528 26.7254ZM31.9348 19.3931C32.428 20.5336 33.7525 21.0584 34.8931 20.5652C36.0336 20.072 36.5584 18.7475 36.0652 17.6069L31.9348 19.3931ZM10.492 17.0816L8.42861 17.9788L10.492 17.0816ZM5.11247 28.2746L8.89259 17.9652L4.66765 16.416L0.887528 26.7254L5.11247 28.2746ZM8.42861 17.9788L13.9399 30.6548L18.0668 28.8606L12.5554 16.1845L8.42861 17.9788ZM21.9957 29.8387L26.9027 6.6124L22.4999 5.68222L17.5929 28.9086L21.9957 29.8387ZM26.4286 6.65995L31.9348 19.3931L36.0652 17.6069L30.559 4.87385L26.4286 6.65995ZM26.9027 6.6124C26.9027 6.61232 26.8976 6.63399 26.8782 6.66644C26.8592 6.69822 26.8339 6.72756 26.8059 6.75123C26.7524 6.79636 26.7069 6.80708 26.683 6.80947C26.6591 6.81187 26.6124 6.8104 26.5511 6.77679C26.5189 6.75917 26.4883 6.73542 26.4633 6.70805C26.4378 6.68011 26.4286 6.65987 26.4286 6.65995L30.559 4.87385C28.9146 1.07114 23.3562 1.62867 22.4999 5.68222L26.9027 6.6124ZM13.9399 30.6548C15.5902 34.4504 21.1402 33.8881 21.9957 29.8387L17.5929 28.9086C17.5927 28.9096 17.5938 28.9041 17.5978 28.8937C17.6019 28.8832 17.6082 28.8698 17.6174 28.8545C17.6364 28.8228 17.6615 28.7935 17.6895 28.7698C17.7429 28.7247 17.7883 28.714 17.8123 28.7115C17.8362 28.7091 17.8829 28.7105 17.9443 28.744C17.9764 28.7616 18.007 28.7852 18.0319 28.8125C18.044 28.8256 18.0528 28.8376 18.0589 28.847C18.065 28.8564 18.0672 28.8615 18.0668 28.8606L13.9399 30.6548ZM8.89259 17.9652C8.88465 17.9868 8.85165 18.0419 8.7892 18.0845C8.7376 18.1197 8.69339 18.1282 8.66521 18.129C8.63703 18.1298 8.5924 18.1239 8.53882 18.0918C8.47398 18.0529 8.43781 17.9999 8.42861 17.9788L12.5554 16.1845C11.0262 12.6672 5.98799 12.8151 4.66765 16.416L8.89259 17.9652Z"
        fill="currentColor"
        clipPath="url(#foo)"
      />
      <path
        id="swoosh"
        d="M2.22498 29.62L6.78032 17.1916C7.40149 15.4969 9.7726 15.4271 10.4923 17.0824L16.0033 29.7577C16.7799 31.5438 19.3916 31.2792 19.7942 29.3737L24.7012 6.14707C25.1042 4.23954 27.7199 3.97715 28.4938 5.76662L34.8999 20.58"
        stroke={`url(#grad-${id})`}
        strokeWidth="4.5"
        strokeLinejoin="round"
        strokeLinecap="round"
        fill="none"
        mask="url(#foo)"
        strokeDasharray={100}
        strokeDashoffset={-100}>
        <animate
          attributeName="stroke-dashoffset"
          values="100;-100"
          dur={animationDuration}
          repeatCount={repeatCount}
          begin="indefinite"
        />
      </path>
      <path
        d="M66.4242 13.2479C68.2554 13.2479 69.7192 13.7873 70.8159 14.866C71.9228 15.9447 72.4763 17.3848 72.4763 19.1861V29.5665H68.333V20.4073C68.333 19.4303 68.0432 18.6518 67.4639 18.0717C66.8949 17.4815 66.1293 17.1863 65.1672 17.1863C64.1533 17.1863 63.3308 17.4865 62.6997 18.087C62.0686 18.6874 61.7531 19.4812 61.7531 20.4684V29.5665H57.6252V20.4073C57.6252 19.4405 57.3304 18.662 56.7406 18.0717C56.1613 17.4815 55.3854 17.1863 54.4129 17.1863C53.4094 17.1863 52.5921 17.4916 51.961 18.1022C51.3299 18.7027 51.0144 19.4914 51.0144 20.4684V29.5665H46.8865V13.6296H51.0144V15.2782C52.2352 13.9247 53.8336 13.2479 55.8095 13.2479C56.9372 13.2479 57.9356 13.4769 58.8046 13.9349C59.684 14.3827 60.3823 15.0187 60.8996 15.843C62.2342 14.1129 64.0757 13.2479 66.4242 13.2479Z"
        fill="currentColor"></path>
      <path
        d="M78.2181 11.2787C78.9527 11.2787 79.5682 11.0345 80.0649 10.546C80.5717 10.0575 80.8252 9.45707 80.8252 8.74469C80.8252 8.03231 80.5717 7.43188 80.0649 6.94339C79.5682 6.45491 78.9527 6.21066 78.2181 6.21066C77.4836 6.21066 76.8629 6.45491 76.356 6.94339C75.8594 7.43188 75.6111 8.03231 75.6111 8.74469C75.6111 9.45707 75.8594 10.0575 76.356 10.546C76.8629 11.0345 77.4836 11.2787 78.2181 11.2787ZM76.1542 13.6296V29.5665H80.2821V13.6296H76.1542Z"
        fill="currentColor"
        fillRule="evenodd"
        clipRule="evenodd"></path>
      <path
        d="M93.5039 13.2479C95.4179 13.2479 96.949 13.8127 98.0974 14.9424C99.2561 16.0618 99.8354 17.5578 99.8354 19.4303V29.5665H95.692V20.7432C95.692 19.6644 95.3662 18.8045 94.7143 18.1633C94.0729 17.512 93.2142 17.1863 92.1383 17.1863C91.0106 17.1863 90.0951 17.5222 89.3915 18.1939C88.6984 18.8553 88.3518 19.7306 88.3518 20.8194V29.5665H84.2239V13.6296H88.3518V15.4003C89.6554 13.9654 91.3727 13.2479 93.5039 13.2479Z"
        fill="currentColor"></path>
      <path
        d="M112.793 17.339H108.433V23.9641C108.433 25.3278 109.177 26.0097 110.667 26.0097C111.298 26.0097 112.007 25.8316 112.793 25.4754V29.2612C111.935 29.7191 110.926 29.9481 109.767 29.9481C107.957 29.9481 106.591 29.4749 105.67 28.5284C104.76 27.5718 104.305 26.2692 104.305 24.6206V17.339H101.729V13.6296H104.305V9.58429H108.433V13.6296H112.793V17.339Z"
        fill="currentColor"></path>
      <path
        d="M123.16 29.948C120.873 29.948 119.042 29.3374 117.666 28.1162C116.29 26.8848 115.602 25.2515 115.602 23.2161V13.6295H119.73V22.6513C119.73 23.669 120.041 24.4831 120.662 25.0937C121.292 25.7043 122.125 26.0096 123.16 26.0096C124.194 26.0096 125.022 25.7043 125.643 25.0937C126.274 24.4831 126.589 23.669 126.589 22.6513V13.6295H130.717V23.2161C130.717 25.2413 130.024 26.8696 128.638 28.101C127.262 29.3323 125.436 29.948 123.16 29.948Z"
        fill="currentColor"></path>
      <path
        d="M153.948 13.2479C155.779 13.2479 157.243 13.7873 158.34 14.866C159.446 15.9447 160 17.3848 160 19.1861V29.5665H155.857V20.4073C155.857 19.4303 155.567 18.6518 154.987 18.0717C154.419 17.4815 153.653 17.1863 152.691 17.1863C151.677 17.1863 150.855 17.4865 150.224 18.087C149.593 18.6874 149.277 19.4812 149.277 20.4684V29.5665H145.149V20.4073C145.149 19.4405 144.854 18.662 144.264 18.0717C143.685 17.4815 142.909 17.1863 141.937 17.1863C140.933 17.1863 140.116 17.4916 139.485 18.1022C138.854 18.7027 138.539 19.4914 138.539 20.4684V29.5665H134.41V13.6296H138.539V15.2782C139.759 13.9247 141.358 13.2479 143.334 13.2479C144.461 13.2479 145.459 13.4769 146.329 13.9349C147.208 14.3827 147.906 15.0187 148.423 15.843C149.758 14.1129 151.599 13.2479 153.948 13.2479Z"
        fill="currentColor"></path>
    </svg>
  );
}

// import React from 'react';
// import styled from 'styled-components';

// const Svg = styled.svg`
//   .iconmark {
//     transition: fill ease-in-out 330ms;
//     fill: currentColor;
//   }
//   path {
//     fill: currentColor;
//   }
//   /* &:hover .iconmark {
//     fill: url(#grad);
//   } */
// `;

// interface MintumLogoProps {
//   primary?: string;
// }

// function MintumLogo(props: MintumLogoProps) {
//   return (
//     <Svg
//       width="160"
//       height="32"
//       viewBox="0 0 160 32"
//       fill="none"
//       xmlns="http://www.w3.org/2000/svg">
//       <path
//         fillRule="evenodd"
//         clipRule="evenodd"
//         d="M21.784 4.45838C22.6153 0.256918 28.3529 -0.209082 30.0308 3.57015L30.0341 3.57761L35.5556 16.1337C36.068 17.2989 35.5375 18.6583 34.3708 19.1701C33.2041 19.6818 31.8429 19.152 31.3305 17.9868L26.1442 6.19302L21.7731 28.2385L21.772 28.244C20.9127 32.5233 14.9454 32.891 13.464 28.8981L8.20666 17.2106C8.17707 17.1448 8.15058 17.0777 8.1273 17.0095L8.12695 17.0084C8.11356 17.0044 8.09464 17.0009 8.0729 17.0007C8.05117 17.0005 8.03232 17.0034 8.01858 17.0071L8.01556 17.008C8.01514 17.0091 8.01465 17.0103 8.01423 17.0115L4.47258 26.5988C4.03152 27.7928 2.70481 28.4036 1.5093 27.9631C0.313787 27.5226 -0.297816 26.1976 0.143245 25.0036L3.68474 15.4168C5.1767 11.3774 10.9748 11.3943 12.4537 15.4061L17.423 26.4531L21.7837 4.46006L21.7839 4.45901L21.784 4.45838Z"
//         className="iconmark"
//       />
//       <path d="M66.4242 13.2479C68.2554 13.2479 69.7192 13.7873 70.8159 14.866C71.9228 15.9447 72.4763 17.3848 72.4763 19.1861V29.5665H68.333V20.4073C68.333 19.4303 68.0432 18.6518 67.4639 18.0717C66.8949 17.4815 66.1293 17.1863 65.1672 17.1863C64.1533 17.1863 63.3308 17.4865 62.6997 18.087C62.0686 18.6874 61.7531 19.4812 61.7531 20.4684V29.5665H57.6252V20.4073C57.6252 19.4405 57.3304 18.662 56.7406 18.0717C56.1613 17.4815 55.3854 17.1863 54.4129 17.1863C53.4094 17.1863 52.5921 17.4916 51.961 18.1022C51.3299 18.7027 51.0144 19.4914 51.0144 20.4684V29.5665H46.8865V13.6296H51.0144V15.2782C52.2352 13.9247 53.8336 13.2479 55.8095 13.2479C56.9372 13.2479 57.9356 13.4769 58.8046 13.9349C59.684 14.3827 60.3823 15.0187 60.8996 15.843C62.2342 14.1129 64.0757 13.2479 66.4242 13.2479Z" />
//       <path
//         fillRule="evenodd"
//         clipRule="evenodd"
//         d="M78.2181 11.2787C78.9527 11.2787 79.5682 11.0345 80.0649 10.546C80.5717 10.0575 80.8252 9.45707 80.8252 8.74469C80.8252 8.03231 80.5717 7.43188 80.0649 6.94339C79.5682 6.45491 78.9527 6.21066 78.2181 6.21066C77.4836 6.21066 76.8629 6.45491 76.356 6.94339C75.8594 7.43188 75.6111 8.03231 75.6111 8.74469C75.6111 9.45707 75.8594 10.0575 76.356 10.546C76.8629 11.0345 77.4836 11.2787 78.2181 11.2787ZM76.1542 13.6296V29.5665H80.2821V13.6296H76.1542Z"
//       />
//       <path d="M93.5039 13.2479C95.4179 13.2479 96.949 13.8127 98.0974 14.9424C99.2561 16.0618 99.8354 17.5578 99.8354 19.4303V29.5665H95.692V20.7432C95.692 19.6644 95.3662 18.8045 94.7143 18.1633C94.0729 17.512 93.2142 17.1863 92.1383 17.1863C91.0106 17.1863 90.0951 17.5222 89.3915 18.1939C88.6984 18.8553 88.3518 19.7306 88.3518 20.8194V29.5665H84.2239V13.6296H88.3518V15.4003C89.6554 13.9654 91.3727 13.2479 93.5039 13.2479Z" />
//       <path d="M112.793 17.339H108.433V23.9641C108.433 25.3278 109.177 26.0097 110.667 26.0097C111.298 26.0097 112.007 25.8316 112.793 25.4754V29.2612C111.935 29.7191 110.926 29.9481 109.767 29.9481C107.957 29.9481 106.591 29.4749 105.67 28.5284C104.76 27.5718 104.305 26.2692 104.305 24.6206V17.339H101.729V13.6296H104.305V9.58429H108.433V13.6296H112.793V17.339Z" />
//       <path d="M123.16 29.948C120.873 29.948 119.042 29.3374 117.666 28.1162C116.29 26.8848 115.602 25.2515 115.602 23.2161V13.6295H119.73V22.6513C119.73 23.669 120.041 24.4831 120.662 25.0937C121.292 25.7043 122.125 26.0096 123.16 26.0096C124.194 26.0096 125.022 25.7043 125.643 25.0937C126.274 24.4831 126.589 23.669 126.589 22.6513V13.6295H130.717V23.2161C130.717 25.2413 130.024 26.8696 128.638 28.101C127.262 29.3323 125.436 29.948 123.16 29.948Z" />
//       <path d="M153.948 13.2479C155.779 13.2479 157.243 13.7873 158.34 14.866C159.446 15.9447 160 17.3848 160 19.1861V29.5665H155.857V20.4073C155.857 19.4303 155.567 18.6518 154.987 18.0717C154.419 17.4815 153.653 17.1863 152.691 17.1863C151.677 17.1863 150.855 17.4865 150.224 18.087C149.593 18.6874 149.277 19.4812 149.277 20.4684V29.5665H145.149V20.4073C145.149 19.4405 144.854 18.662 144.264 18.0717C143.685 17.4815 142.909 17.1863 141.937 17.1863C140.933 17.1863 140.116 17.4916 139.485 18.1022C138.854 18.7027 138.539 19.4914 138.539 20.4684V29.5665H134.41V13.6296H138.539V15.2782C139.759 13.9247 141.358 13.2479 143.334 13.2479C144.461 13.2479 145.459 13.4769 146.329 13.9349C147.208 14.3827 147.906 15.0187 148.423 15.843C149.758 14.1129 151.599 13.2479 153.948 13.2479Z" />
//       <defs>
//         <linearGradient
//           id="grad"
//           gradientTransform="rotate(90, 0.5, 0.5)"
//           x1="50%"
//           y1="0%"
//           x2="50%"
//           y2="100%">
//           <stop stopColor="#DB09E8" stopOpacity="1" offset="0%">
//             <animate
//               attributeName="stop-color"
//               values="#00D0D8;#0067FF;#3E45FF;#DB09E8;#00D0D8"
//               dur="3s"
//               repeatCount="indefinite"
//             />
//           </stop>
//           <stop stopColor="#3E45FF" stopOpacity="1" offset="100%">
//             <animate
//               attributeName="stop-color"
//               values="#0067FF;#3E45FF;#DB09E8;#00D0D8;#0067FF"
//               dur="3s"
//               repeatCount="indefinite"
//             />
//           </stop>
//         </linearGradient>
//       </defs>
//     </Svg>
//   );
// }

// export default MintumLogo;
