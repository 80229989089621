import { css } from 'styled-components';
import { responseFont } from './mixins';
import type { ThemeProps } from './theme';

export const sizes = {
  bodySizeMin: 22,
  bodySize: 27,
  bodyLineHeight: 45,
  bodyMSizeMin: 18,
  bodyMSize: 22,
  bodyMLineHeight: 36,
  bodySSizeMin: 15,
  bodySSize: 17,
  bodySLineHeight: 28,
  bodyXsSizeMin: 12,
  bodyXsSize: 14,
  bodyXsLineHeight: 23,
  heroSizeMin: 48,
  heroSize: 82,
  heroLineHeight: 104,
  h1SizeMin: 36,
  h1Size: 82,
  h1LineHeight: 104,
  h2SizeMin: 32,
  h2Size: 66,
  h2LineHeight: 82,
  h3SizeMin: 32,
  h3Size: 53,
  h3LineHeight: 66,
  h4SizeMin: 28,
  h4Size: 42,
  h4LineHeight: 53,
  h5SizeMin: 26,
  h5Size: 43,
  h5LineHeight: 34,
  h6SizeMin: 22,
  h6Size: 24,
  h6LineHeight: 34,
  h7SizeMin: 20,
  h7Size: 22,
  h7LineHeight: 27,
};

export const body = (props: ThemeProps) => css`
  font-family: ${props.theme.fontFamily};
  font-weight: normal;

  ${responseFont(sizes.bodySizeMin, sizes.bodySize)(props)}

  line-height: ${sizes.bodyLineHeight / sizes.bodySize};
`;

export const bodyM = (props: ThemeProps) => css`
  font-family: ${props.theme.fontFamily};
  font-weight: normal;

  ${responseFont(sizes.bodyMSizeMin, sizes.bodyMSize)(props)}

  line-height: ${sizes.bodyMLineHeight / sizes.bodyMSize};
`;

export const bodyS = (props: ThemeProps) => css`
  font-family: ${props.theme.fontFamily};
  font-weight: normal;

  ${responseFont(sizes.bodySSizeMin, sizes.bodySSize)(props)}

  line-height: ${sizes.bodySLineHeight / sizes.bodySSize};
`;

export const bodyXs = (props: ThemeProps) => css`
  font-family: ${props.theme.fontFamily};
  font-weight: normal;

  ${responseFont(sizes.bodyXsSizeMin, sizes.bodyXsSize)(props)}

  line-height: ${sizes.bodyXsLineHeight / sizes.bodyXsSize};
`;

export const hero = (props: ThemeProps) => css`
  font-family: ${props.theme.fontFamily};
  font-weight: 400;

  ${responseFont(sizes.heroSizeMin, sizes.heroSize)(props)}

  line-height: ${sizes.heroLineHeight / sizes.heroSize};
`;

export const h1 = (props: ThemeProps) => css`
  font-family: ${props.theme.fontFamily};
  font-weight: 400;

  ${responseFont(sizes.h1SizeMin, sizes.h1Size)(props)}

  line-height: ${sizes.h1LineHeight / sizes.h1Size};
`;

export const h2 = (props: ThemeProps) => css`
  font-family: ${props.theme.fontFamily};
  font-weight: 400;

  ${responseFont(sizes.h2SizeMin, sizes.h2Size)(props)}

  line-height: ${sizes.h2LineHeight / sizes.h2Size};
`;

export const h3 = (props: ThemeProps) => css`
  font-family: ${props.theme.fontFamily};
  font-weight: 400;

  ${responseFont(sizes.h3SizeMin, sizes.h3Size)(props)}

  line-height: ${sizes.h3LineHeight / sizes.h3Size};
`;

export const h4 = (props: ThemeProps) => css`
  font-family: ${props.theme.fontFamily};
  font-weight: 400;

  ${responseFont(sizes.h4SizeMin, sizes.h4Size)(props)}

  line-height: ${sizes.h4LineHeight / sizes.h4Size};
`;

export const h5 = (props: ThemeProps) => css`
  font-family: ${props.theme.fontFamily};
  font-weight: 400;

  ${responseFont(sizes.h5SizeMin, sizes.h5Size)(props)}

  line-height: ${sizes.h5LineHeight / sizes.h5Size};
`;

export const h6 = (props: ThemeProps) => css`
  font-family: ${props.theme.fontFamily};
  font-weight: 400;

  ${responseFont(sizes.h6SizeMin, sizes.h6Size)(props)}

  line-height: ${sizes.h6LineHeight / sizes.h6Size};
`;
export const h7 = (props: ThemeProps) => css`
  font-family: ${props.theme.fontFamily};
  font-weight: 400;

  ${responseFont(sizes.h7SizeMin, sizes.h7Size)(props)}

  line-height: ${sizes.h7LineHeight / sizes.h7Size};
`;
