import {
  ApolloClient,
  ApolloLink,
  concat,
  createHttpLink,
  fromPromise,
  InMemoryCache,
  split,
} from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import { RetryLink } from '@apollo/client/link/retry';
import {
  getMainDefinition,
  relayStylePagination,
} from '@apollo/client/utilities';
import { showNotification } from '@mantine/notifications';
import * as Sentry from '@sentry/nextjs';
import { CloseCode } from 'graphql-ws';
import type { JwtPayload } from 'jwt-decode';
import jwtDecode from 'jwt-decode';
import pkg from '../../package.json';
import { authStore } from './auth-store';
import { WebSocketLink } from './websocket-link';

const EXPIRE_OFFSET = 60 * 1000;

let getAccessTokenPromise: Promise<string | null> | undefined;

const httpLink = createHttpLink({
  uri: `${authStore.getState().apiUrl}/graphql`,
});

const errorLink = onError(
  ({ graphQLErrors = [], networkError, forward, operation }) => {
    const errorCodes = graphQLErrors.map((err) => err.extensions?.code);
    const errorMessage = graphQLErrors.map((err) => err.message);

    // Skip WebSocket network errors
    if (networkError instanceof CloseEvent) {
      return;
    }

    // Test for authentication errors
    if (
      errorCodes.includes('UNAUTHENTICATED') ||
      errorMessage.some((message) => message.startsWith('Access denied!'))
    ) {
      // Unauthorized. You need to sign in.
      return fromPromise(getAuthorization('http', true))
        .filter((tokens) => !!tokens)
        .flatMap(() => {
          const { accessToken } = authStore.getState();
          operation.setContext({
            headers: {
              ...operation.getContext().headers,
              authorization: accessToken ? `Bearer ${accessToken}` : undefined,
            },
          });
          return forward(operation);
        });
    }

    // Log errors
    graphQLErrors.forEach((err) => {
      // Capture with sentry
      Sentry.captureException(err);
    });

    if (networkError) {
      // Log network errors
      Sentry.captureException(networkError);
      // Notify user
      showNotification({
        title: 'Network error',
        message: `${networkError?.message}. Our server is not responding or your internet connection is down.`,
        color: 'red',
      });
    }

    console.log({ graphQLErrors, networkError });
  }
);

const retryLink = new RetryLink({
  attempts: {
    max: 3,
    retryIf(err, operation) {
      if ([].concat(err).length === 0) {
        console.log('Retrying operation', { operation });
        // only retry when there are no errors
        return true;
      }
      return false;
    },
  },
});

// Get the currently available access token
const getAuthorization = async (transport = 'http', force = false) => {
  // wait for pending access token promise
  if (getAccessTokenPromise) {
    await getAccessTokenPromise;
  } else {
    // rehydrate auth store from localStorage
    await authStore.persist.rehydrate();
  }
  // read auth store state
  let auth = authStore.getState();
  // if access token available
  if (auth.accessToken) {
    try {
      // deconstruct and check if expired
      const decoded = jwtDecode<JwtPayload & { data?: { userId?: string } }>(
        auth.accessToken
      );
      if (decoded.data) {
        Sentry.setUser({
          id: decoded.data.userId,
        });
      }
      if (decoded.exp) {
        const expiresAt = decoded.exp * 1000;
        const expiresIn = expiresAt - Date.now();
        if (expiresIn <= EXPIRE_OFFSET || force) {
          if (!getAccessTokenPromise) {
            getAccessTokenPromise = auth.actions.refreshTokens().finally(() => {
              getAccessTokenPromise = undefined;
            });
          }
          await getAccessTokenPromise;
          // refresh auth store
          auth = authStore.getState();
        }
      }

      if (auth.accessToken) {
        return `Bearer ${auth.accessToken}`;
      }
    } catch (err) {
      console.error('failed to parse access token', err);
      auth.actions.logout();
      cache.reset();
    }
  }

  return undefined;
};

const authLink = setContext(async (req, { headers }) => ({
  headers: {
    ...headers,
    authorization: await getAuthorization(),
  },
}));

let link = ApolloLink.from([retryLink, errorLink, authLink]);

if (process.browser) {
  let listener: () => void | undefined;
  let lastAccessToken: string | undefined;
  const websocketLink = new WebSocketLink({
    url: `${authStore.getState().apiUrl.replace(/^http/, 'ws')}/graphql`,
    connectionParams: async () => {
      const Authorization = await getAuthorization('ws');
      lastAccessToken = Authorization;
      if (!Authorization) {
        return {};
      }
      return {
        Authorization,
      };
    },
    lazy: true,
    on: {
      connected: (socket: any) => {
        listener = authStore.subscribe((state) => {
          const nextAccessToken = `Bearer ${state.accessToken}`;
          if (lastAccessToken !== nextAccessToken) {
            if (socket.readyState === socket.OPEN) {
              socket.close(CloseCode.Forbidden, 'Forbidden');
            }
          }
        });
      },
      error: (err) => {
        console.log('WebSocket error', err);
      },
      closed: () => {
        listener?.();
      },
    },
  });

  // add http/ws link
  link = concat(
    link,
    split(
      ({ query }) => {
        const definition = getMainDefinition(query);
        return (
          definition.kind === 'OperationDefinition' &&
          definition.operation === 'subscription'
        );
      },
      websocketLink,
      httpLink
    )
  );
} else {
  link = concat(link, httpLink);
}

const cache = new InMemoryCache({
  typePolicies: {
    User: {
      fields: {
        transactions: relayStylePagination(),
      },
    },
  },
});

export const client = new ApolloClient({
  link,
  cache,
  name: 'web',
  version: pkg.version,
});
