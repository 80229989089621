import type { JwtPayload } from 'jwt-decode';
import jwtDecode from 'jwt-decode';
import { omit } from 'lodash';
import createHook from 'zustand';
import { persist } from 'zustand/middleware';
import type { GetState } from 'zustand/vanilla';
import create from 'zustand/vanilla';
import { config } from '../config';
import type { MultiFactorType } from '../graphql/generated';
import { gist } from './metrics';

export enum MultiFactorStatus {
  REQUIRED = 0,
  NOT_REQUIRED = -1,
  VERIFIED = 1,
}

interface MultiFactorTypes<T> {
  [MultiFactorType.App]?: T;
  [MultiFactorType.Email]?: T;
  [MultiFactorType.Sms]?: T;
  [MultiFactorType.SecurityKey]?: T;
}

export interface Tokens {
  accessToken?: string;
  refreshToken?: string;
  mfa?: MultiFactorTypes<MultiFactorStatus>;
  skotp?: boolean;
}

export interface LoginPayload {
  nationalId: string;
  fullName: string;
}

interface AuthUser {
  fullName: string | undefined;
  emailAddress: string | undefined;
  nationalId: string | undefined;
  priceTable?: any;
}

interface AuthStore extends Tokens {
  apiUrl: string;
  user?: AuthUser;
  loggedIn(): boolean;
  actions: {
    setTokens(tokens: Tokens): void;
    refreshTokens(payload?: {
      otp?: MultiFactorTypes<string>;
      logoutOnError?: boolean;
    }): Promise<{ success: boolean }>;
    sendMfa(
      type: MultiFactorType.Sms | MultiFactorType.Email,
      reason: MfaReason
    ): Promise<boolean>;
    loginNationalId(payload: LoginPayload): Promise<boolean>;
    loginPhone(phoneNumber: string): Promise<boolean>;
    expiresIn(): number | null;
    logout(): Promise<void>;
  };
}

export enum MfaReason {
  LOGIN = 'LOGIN',
  UPDATE_EMAIL = 'UPDATE_EMAIL',
  UPDATE_PHONE = 'UPDATE_PHONE',
  UPDATE_SECURITY_PREFERENCES = 'UPDATE_SECURITY_PREFERENCES',
  CHANGE_MFA_APP = 'CHANGE_MFA_APP',
  ADD_SECURITY_KEY = 'ADD_SECURITY_KEY',
  REMOVE_SECURITY_KEY = 'REMOVE_SECURITY_KEY',
  ADD_ADDRESS = 'ADD_ADDRESS',
  REMOVE_ADDRESS = 'REMOVE_ADDRESS',
  WITHDRAW = 'WITHDRAW',
}

export const authStore = create(
  persist(
    (set, get: GetState<AuthStore>) => ({
      apiUrl: config.apiUrl,
      accessToken: undefined,
      refreshToken: undefined,
      mfa: undefined,
      skotp: undefined,
      user: undefined as AuthUser | undefined,
      loggedIn() {
        const { accessToken, mfa } = get();
        return (
          !!accessToken &&
          Object.values(mfa || {}).filter(
            (s) => s === MultiFactorStatus.REQUIRED
          ).length === 0
        );
      },
      actions: {
        setTokens({ accessToken, refreshToken, mfa, skotp }: Tokens) {
          set({ accessToken, refreshToken, mfa, skotp });
        },
        expiresIn() {
          const { accessToken } = get();
          if (accessToken) {
            const decoded = jwtDecode<JwtPayload>(accessToken);
            if (decoded.exp) {
              return Date.now() - decoded.exp * 1000;
            }
          }
          return null;
        },
        async sendMfa(type: 'SMS' | 'EMAIL', reason: MfaReason) {
          const { accessToken } = get();
          const res = await fetch(`${get().apiUrl}/auth/send-mfa`, {
            method: 'POST',
            headers: {
              'content-type': 'application/json',
            },
            body: JSON.stringify({
              accessToken,
              type,
              reason,
            }),
          });
          const data = await res.json();
          return data.success;
        },
        async refreshTokens(
          {
            otp,
            logoutOnError = true,
          }: { otp?: MultiFactorTypes<string>; logoutOnError?: boolean } = {
            logoutOnError: true,
          }
        ) {
          await authStore.persist.rehydrate();
          const { accessToken, refreshToken, actions } = get();
          if (accessToken && refreshToken) {
            const res = await fetch(`${get().apiUrl}/auth/refresh`, {
              method: 'POST',
              headers: {
                'content-type': 'application/json',
              },
              body: JSON.stringify({
                accessToken,
                refreshToken,
                otp,
              }),
            });
            const tokens = await res.json();
            if (tokens.success) {
              actions.setTokens(tokens);
              // Write to localstorage
              await new Promise((r) => setTimeout(r, 444));
            } else if (logoutOnError) {
              actions.logout();
              return null;
            }
            return tokens;
          }
          return null;
        },
        async loginNationalId({ nationalId, fullName }: LoginPayload) {
          const res = await fetch(`${get().apiUrl}/auth/login`, {
            method: 'POST',
            headers: {
              'content-type': 'application/json',
            },
            body: JSON.stringify({ nationalId, fullName }),
          });
          const data = await res.json();
          if (!data.success) {
            console.error('failed to login', data.message);
            return false;
          }
          get().actions.setTokens(data);
          return true;
        },
        async loginPhone(phoneNumber: string) {
          const res = await fetch(`${get().apiUrl}/auth/phone`, {
            method: 'POST',
            headers: {
              'content-type': 'application/json',
            },
            body: JSON.stringify({ phoneNumber }),
          });
          const data = await res.json();
          if (!data.success) {
            throw new Error(data.message);
          }
          get().actions.setTokens(data);
          return true;
        },
        async logout() {
          const { accessToken } = get();
          this.setTokens({
            accessToken: undefined,
            refreshToken: undefined,
            mfa: undefined,
            skotp: undefined,
          });
          gist.chat('shutdown');
          const res = await fetch(`${get().apiUrl}/auth/logout`, {
            method: 'POST',
            headers: {
              'content-type': 'application/json',
              authorization: `Bearer ${accessToken}`,
            },
          });
          const data = await res.json();
          if (!data.success) {
            console.error('failed to logout', data.message);
          }
        },
      },
    }),
    {
      name: '@tokens',
      partialize(state) {
        // @todo if development, also omit apiUrl.
        return omit(state, ['actions', 'apiUrl', 'loggedIn']);
      },
      version: 3,
    }
  )
);

if (typeof window !== 'undefined') {
  window.addEventListener('storage', async (e) => {
    if (e.storageArea === window.localStorage && e.key === '@tokens') {
      await authStore.persist.rehydrate();
      if (!authStore.getState().loggedIn()) {
        window.location.href = '/';
      }
    }
  });
}

export const useAuthStore = createHook(authStore);
