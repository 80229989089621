import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K];
};
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]?: Maybe<T[SubKey]>;
};
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]: Maybe<T[SubKey]>;
};
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** The javascript `Date` as string. Type represents date and time as the ISO Date string. */
  DateTime: any;
  /** The `JSON` scalar type represents JSON values as specified by [ECMA-404](http://www.ecma-international.org/publications/files/ECMA-ST/ECMA-404.pdf). */
  JSON: any;
};

export type CreateAddressInput = {
  address: Scalars['String'];
  currency: Currency;
  name?: InputMaybe<Scalars['String']>;
};

/** Currency in which a resource uses */
export enum Currency {
  Isk = 'ISK',
  Iskt = 'ISKT',
}

/** Know Your Customer status */
export enum KycStatus {
  AdvancedRequired = 'ADVANCED_REQUIRED',
  BasicRequired = 'BASIC_REQUIRED',
  Ok = 'OK',
}

export enum MultiFactorType {
  App = 'APP',
  Email = 'EMAIL',
  SecurityKey = 'SECURITY_KEY',
  Sms = 'SMS',
}

export type Mutation = {
  __typename?: 'Mutation';
  completeSignup?: Maybe<User>;
  completeSurvey: User;
  createAddress: UserAddress;
  exchange?: Maybe<Transaction>;
  markNotificationRead: Notification;
  registerDeviceToken?: Maybe<UserDeviceToken>;
  removeAddress: Scalars['Boolean'];
  removeSecurityKey: Scalars['Boolean'];
  securityKeyOptions: Scalars['JSON'];
  securityKeyResult: Scalars['Boolean'];
  setPrimaryAddress: UserAddress;
  setupMultiFactor?: Maybe<SetupMultifactorResponse>;
  terminateAllUserSessions: Scalars['Float'];
  terminateUserSession: Scalars['Boolean'];
  updateEmailAddress: Scalars['Boolean'];
  updatePhoneNumber: Scalars['Boolean'];
  updateProfile: User;
  updateRiskCategory: UserAml;
  updateSecurityPreferences: User;
  verifyMultiFactor: Scalars['Boolean'];
  withdraw?: Maybe<Transaction>;
};

export type MutationCompleteSignupArgs = {
  email: Scalars['String'];
};

export type MutationCompleteSurveyArgs = {
  answers: Scalars['JSON'];
  type: KycStatus;
};

export type MutationCreateAddressArgs = {
  input: CreateAddressInput;
  otp?: InputMaybe<OneTimePasswordInput>;
};

export type MutationExchangeArgs = {
  amount: Scalars['Float'];
  description?: InputMaybe<Scalars['String']>;
  dstWalletId: Scalars['ID'];
  srcWalletId: Scalars['ID'];
};

export type MutationMarkNotificationReadArgs = {
  id: Scalars['ID'];
};

export type MutationRegisterDeviceTokenArgs = {
  clientId: Scalars['String'];
  fcmToken: Scalars['String'];
  platform: Scalars['String'];
  remove: Scalars['Boolean'];
};

export type MutationRemoveAddressArgs = {
  addressId: Scalars['ID'];
  otp?: InputMaybe<OneTimePasswordInput>;
};

export type MutationRemoveSecurityKeyArgs = {
  id: Scalars['ID'];
  otp?: InputMaybe<OneTimePasswordInput>;
};

export type MutationSecurityKeyOptionsArgs = {
  type: Scalars['String'];
};

export type MutationSecurityKeyResultArgs = {
  name?: InputMaybe<Scalars['String']>;
  response: Scalars['String'];
  type: Scalars['String'];
};

export type MutationSetPrimaryAddressArgs = {
  addressId: Scalars['ID'];
};

export type MutationTerminateUserSessionArgs = {
  id: Scalars['ID'];
};

export type MutationUpdateEmailAddressArgs = {
  emailAddress: Scalars['String'];
  otp?: InputMaybe<OneTimePasswordInput>;
};

export type MutationUpdatePhoneNumberArgs = {
  otp?: InputMaybe<OneTimePasswordInput>;
  phoneNumber: Scalars['String'];
};

export type MutationUpdateProfileArgs = {
  input: UpdateProfileInput;
};

export type MutationUpdateRiskCategoryArgs = {
  notes?: InputMaybe<Scalars['String']>;
  riskCategory: RiskCategory;
  userId: Scalars['ID'];
};

export type MutationUpdateSecurityPreferencesArgs = {
  otp?: InputMaybe<OneTimePasswordInput>;
  preferences?: InputMaybe<SecurityPreferencesInput>;
  types?: InputMaybe<Array<MultiFactorType>>;
};

export type MutationVerifyMultiFactorArgs = {
  code?: InputMaybe<Scalars['String']>;
};

export type MutationWithdrawArgs = {
  addressId: Scalars['ID'];
  amount: Scalars['Float'];
  description?: InputMaybe<Scalars['String']>;
  requestLamports?: InputMaybe<Scalars['Boolean']>;
  walletId: Scalars['ID'];
};

export type Notification = {
  __typename?: 'Notification';
  actionLabel?: Maybe<Scalars['String']>;
  actionUrl?: Maybe<Scalars['String']>;
  body: Scalars['String'];
  createdAt: Scalars['DateTime'];
  id: Scalars['String'];
  readAt?: Maybe<Scalars['DateTime']>;
  title: Scalars['String'];
};

export type NotificationConnection = {
  __typename?: 'NotificationConnection';
  edges: Array<NotificationEdge>;
  pageInfo: PageInfo;
};

export type NotificationEdge = {
  __typename?: 'NotificationEdge';
  /** Used in `before` and `after` args */
  cursor: Scalars['String'];
  node: Notification;
};

export type OneTimePasswordInput = {
  APP?: InputMaybe<Scalars['String']>;
  EMAIL?: InputMaybe<Scalars['String']>;
  SECURITY_KEY?: InputMaybe<Scalars['String']>;
  SMS?: InputMaybe<Scalars['String']>;
};

export type PageInfo = {
  __typename?: 'PageInfo';
  endCursor?: Maybe<Scalars['String']>;
  hasNextPage: Scalars['Boolean'];
  hasPreviousPage: Scalars['Boolean'];
  startCursor?: Maybe<Scalars['String']>;
};

export type Preferences = {
  __typename?: 'Preferences';
  fiatDepositAction: Scalars['String'];
  tokenDepositAction: Scalars['String'];
};

export type Query = {
  __typename?: 'Query';
  Address: UserAddress;
  Transaction: Transaction;
  User: User;
  UserSession: UserSession;
  Wallet: Wallet;
  allAmlAlerts: UserAmlAlertConnection;
  allTransactions: Array<Transaction>;
  allUsers: UserConnection;
  allWallets: Array<Wallet>;
  me: User;
  priceTable: Scalars['JSON'];
  stats: Scalars['JSON'];
};

export type QueryAddressArgs = {
  addressId: Scalars['ID'];
};

export type QueryTransactionArgs = {
  transactionId: Scalars['ID'];
};

export type QueryUserArgs = {
  id: Scalars['ID'];
};

export type QueryUserSessionArgs = {
  userSessionId: Scalars['ID'];
};

export type QueryWalletArgs = {
  walletId: Scalars['ID'];
};

export type QueryAllAmlAlertsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  userId?: InputMaybe<Scalars['ID']>;
};

export type QueryAllUsersArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};

export type QueryPriceTableArgs = {
  general?: InputMaybe<Scalars['Boolean']>;
};

export enum RiskCategory {
  High = 'HIGH',
  Low = 'LOW',
  Medium = 'MEDIUM',
  Unknown = 'UNKNOWN',
  VeryHigh = 'VERY_HIGH',
}

export type SecurityPreferencesInput = {
  multiFactorWithdrawal?: InputMaybe<Scalars['Boolean']>;
  securityKeyLogin?: InputMaybe<Scalars['Boolean']>;
  securityKeyRequiresOTP?: InputMaybe<Scalars['Boolean']>;
};

export type SetupMultifactorResponse = {
  __typename?: 'SetupMultifactorResponse';
  keyUri?: Maybe<Scalars['String']>;
  mnemonicWords?: Maybe<Array<Scalars['String']>>;
  success: Scalars['Boolean'];
};

export type Subscription = {
  __typename?: 'Subscription';
  newNotification?: Maybe<Notification>;
  transactionChanges?: Maybe<Transaction>;
};

export type Transaction = {
  __typename?: 'Transaction';
  /** Transaction amount */
  amount: Scalars['Float'];
  createdAt: Scalars['DateTime'];
  currency: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  /** Destination account address. Bank account or solana address */
  dstAddress?: Maybe<Scalars['String']>;
  dstWallet?: Maybe<Wallet>;
  externalTransactionId?: Maybe<Scalars['String']>;
  /** Transaction fee in ISK */
  fee: Scalars['Float'];
  feeTransactionId?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  internalTransactionId?: Maybe<Scalars['String']>;
  /** Fee for lamports */
  lamportsInFiat: Scalars['Float'];
  /** Amount of lamports received */
  lamportsReceived: Scalars['Float'];
  lamportsTransactionId?: Maybe<Scalars['String']>;
  processedAt?: Maybe<Scalars['DateTime']>;
  requestLamports: Scalars['Boolean'];
  settledAt?: Maybe<Scalars['DateTime']>;
  /** Source account address. Bank account or solana address */
  srcAddress?: Maybe<Scalars['String']>;
  srcWallet?: Maybe<Wallet>;
  status: TransactionStatus;
  statusMessage?: Maybe<Scalars['String']>;
  type: TransactionType;
  wallet: Wallet;
};

export type TransactionConnection = {
  __typename?: 'TransactionConnection';
  edges: Array<TransactionEdge>;
  pageInfo: PageInfo;
};

export type TransactionEdge = {
  __typename?: 'TransactionEdge';
  /** Used in `before` and `after` args */
  cursor: Scalars['String'];
  node: Transaction;
};

export enum TransactionStatus {
  Cancelled = 'CANCELLED',
  Completed = 'COMPLETED',
  Expired = 'EXPIRED',
  Failed = 'FAILED',
  Pending = 'PENDING',
  Processing = 'PROCESSING',
  Unknown = 'UNKNOWN',
}

export enum TransactionType {
  Deposit = 'DEPOSIT',
  Exchange = 'EXCHANGE',
  Unknown = 'UNKNOWN',
  Withdraw = 'WITHDRAW',
}

export type UpdateProfileInput = {
  avatarUrl?: InputMaybe<Scalars['String']>;
  fiatDepositAction?: InputMaybe<Scalars['String']>;
  fullName?: InputMaybe<Scalars['String']>;
  tokenDepositAction?: InputMaybe<Scalars['String']>;
};

export type User = {
  __typename?: 'User';
  addressBook: Array<UserAddress>;
  aml?: Maybe<UserAml>;
  auths: Array<UserAuth>;
  avatarUrl?: Maybe<Scalars['String']>;
  emailAddress?: Maybe<Scalars['String']>;
  emailAddressVerified?: Maybe<Scalars['Boolean']>;
  frozenUntil?: Maybe<Scalars['DateTime']>;
  fullName?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  kycStatus: KycStatus;
  multiFactorMethods: Array<MultiFactorType>;
  multiFactorWithdrawal: Scalars['Boolean'];
  nationalId: Scalars['String'];
  notifications: NotificationConnection;
  phoneNumber?: Maybe<Scalars['String']>;
  preferences: Preferences;
  riskCategory: RiskCategory;
  securityKeyLogin: Scalars['Boolean'];
  securityKeyRequiresOTP: Scalars['Boolean'];
  securityOnboardedAt?: Maybe<Scalars['DateTime']>;
  sessions: Array<UserSession>;
  termsAgreedAt?: Maybe<Scalars['DateTime']>;
  transactions: TransactionConnection;
  unreadNotificationsCount: Scalars['Int'];
  wallets: Array<Wallet>;
};

export type UserNotificationsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};

export type UserTransactionsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};

export type UserAml = {
  __typename?: 'UserAML';
  computedRiskCategory?: Maybe<RiskCategory>;
  id: Scalars['ID'];
  kycAdvancedAnswers?: Maybe<Scalars['JSON']>;
  kycBasicAnswers?: Maybe<Scalars['JSON']>;
  manualRiskCategory?: Maybe<RiskCategory>;
  nationalRegistry?: Maybe<Scalars['JSON']>;
  notes?: Maybe<Scalars['String']>;
  pepList?: Maybe<Scalars['JSON']>;
  sanctionsList?: Maybe<Scalars['JSON']>;
};

export type UserAmlAlert = {
  __typename?: 'UserAMLAlert';
  action: Scalars['String'];
  body: Scalars['JSON'];
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  session?: Maybe<UserSession>;
  title: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  user: User;
};

export type UserAmlAlertConnection = {
  __typename?: 'UserAMLAlertConnection';
  edges: Array<UserAmlAlertEdge>;
  pageInfo: PageInfo;
};

export type UserAmlAlertEdge = {
  __typename?: 'UserAMLAlertEdge';
  /** Used in `before` and `after` args */
  cursor: Scalars['String'];
  node: UserAmlAlert;
};

export type UserAddress = {
  __typename?: 'UserAddress';
  address: Scalars['String'];
  balance?: Maybe<Scalars['Float']>;
  createdAt: Scalars['DateTime'];
  currency: Currency;
  id: Scalars['String'];
  isPrimary: Scalars['Boolean'];
  name?: Maybe<Scalars['String']>;
  solanaBalance?: Maybe<Scalars['Float']>;
  transactions: Array<Transaction>;
};

export type UserAuth = {
  __typename?: 'UserAuth';
  createdAt: Scalars['DateTime'];
  hardware?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  transports: Array<Scalars['String']>;
  type: MultiFactorType;
  updatedAt: Scalars['DateTime'];
};

export type UserConnection = {
  __typename?: 'UserConnection';
  edges: Array<UserEdge>;
  pageInfo: PageInfo;
};

export type UserDeviceToken = {
  __typename?: 'UserDeviceToken';
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  updatedAt: Scalars['DateTime'];
};

export type UserEdge = {
  __typename?: 'UserEdge';
  /** Used in `before` and `after` args */
  cursor: Scalars['String'];
  node: User;
};

export type UserSession = {
  __typename?: 'UserSession';
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  ip?: Maybe<Scalars['String']>;
  updatedAt: Scalars['DateTime'];
  userAgent?: Maybe<Scalars['String']>;
};

export type Wallet = {
  __typename?: 'Wallet';
  availableBalance: Scalars['Float'];
  currency: Currency;
  currentBalance: Scalars['Float'];
  id: Scalars['ID'];
  publicKey?: Maybe<Scalars['String']>;
  transactions: Array<Transaction>;
  user: User;
};

export type NotificationFragmentFragment = {
  __typename?: 'Notification';
  id: string;
  title: string;
  body: string;
  actionLabel?: string | null;
  actionUrl?: string | null;
  readAt?: any | null;
  createdAt: any;
};

export type TransactionFragmentFragment = {
  __typename?: 'Transaction';
  id: string;
  currency: string;
  type: TransactionType;
  status: TransactionStatus;
  dstAddress?: string | null;
  srcAddress?: string | null;
  statusMessage?: string | null;
  externalTransactionId?: string | null;
  internalTransactionId?: string | null;
  feeTransactionId?: string | null;
  description?: string | null;
  amount: number;
  fee: number;
  createdAt: any;
  processedAt?: any | null;
  settledAt?: any | null;
};

export type UserAddressFragmentFragment = {
  __typename?: 'UserAddress';
  id: string;
  currency: Currency;
  name?: string | null;
  address: string;
  balance?: number | null;
  solanaBalance?: number | null;
  createdAt: any;
  isPrimary: boolean;
};

export type UserAuthFragmentFragment = {
  __typename?: 'UserAuth';
  id: string;
  name?: string | null;
  hardware?: string | null;
  type: MultiFactorType;
  transports: Array<string>;
  createdAt: any;
  updatedAt: any;
};

export type UserSessionFragmentFragment = {
  __typename?: 'UserSession';
  id: string;
  userAgent?: string | null;
  ip?: string | null;
  createdAt: any;
  updatedAt: any;
};

export type UserFragmentFragment = {
  __typename?: 'User';
  id: string;
  fullName?: string | null;
  avatarUrl?: string | null;
  nationalId: string;
  emailAddress?: string | null;
  emailAddressVerified?: boolean | null;
  phoneNumber?: string | null;
  termsAgreedAt?: any | null;
  multiFactorMethods: Array<MultiFactorType>;
  multiFactorWithdrawal: boolean;
  securityKeyRequiresOTP: boolean;
  securityKeyLogin: boolean;
  securityOnboardedAt?: any | null;
  frozenUntil?: any | null;
  kycStatus: KycStatus;
  preferences: {
    __typename?: 'Preferences';
    tokenDepositAction: string;
    fiatDepositAction: string;
  };
};

export type WalletFragmentFragment = {
  __typename?: 'Wallet';
  id: string;
  currency: Currency;
  currentBalance: number;
  availableBalance: number;
  publicKey?: string | null;
};

export type CompleteSignupMutationVariables = Exact<{
  email: Scalars['String'];
}>;

export type CompleteSignupMutation = {
  __typename?: 'Mutation';
  completeSignup?: {
    __typename?: 'User';
    id: string;
    termsAgreedAt?: any | null;
    emailAddress?: string | null;
    emailAddressVerified?: boolean | null;
  } | null;
};

export type CompleteSurveyMutationVariables = Exact<{
  type: KycStatus;
  answers: Scalars['JSON'];
}>;

export type CompleteSurveyMutation = {
  __typename?: 'Mutation';
  completeSurvey: { __typename?: 'User'; id: string; kycStatus: KycStatus };
};

export type CreateAddressMutationVariables = Exact<{
  input: CreateAddressInput;
  otp?: InputMaybe<OneTimePasswordInput>;
}>;

export type CreateAddressMutation = {
  __typename?: 'Mutation';
  createAddress: {
    __typename?: 'UserAddress';
    id: string;
    currency: Currency;
    name?: string | null;
    address: string;
    balance?: number | null;
    solanaBalance?: number | null;
    createdAt: any;
    isPrimary: boolean;
  };
};

export type DoExchangeMutationVariables = Exact<{
  amount: Scalars['Float'];
  dstWalletId: Scalars['ID'];
  srcWalletId: Scalars['ID'];
}>;

export type DoExchangeMutation = {
  __typename?: 'Mutation';
  exchange?: {
    __typename?: 'Transaction';
    id: string;
    currency: string;
    type: TransactionType;
    status: TransactionStatus;
    dstAddress?: string | null;
    srcAddress?: string | null;
    statusMessage?: string | null;
    externalTransactionId?: string | null;
    internalTransactionId?: string | null;
    feeTransactionId?: string | null;
    description?: string | null;
    amount: number;
    fee: number;
    createdAt: any;
    processedAt?: any | null;
    settledAt?: any | null;
    dstWallet?: {
      __typename?: 'Wallet';
      id: string;
      currency: Currency;
      currentBalance: number;
      availableBalance: number;
      publicKey?: string | null;
    } | null;
  } | null;
};

export type MarkNotificationReadMutationVariables = Exact<{
  id: Scalars['ID'];
}>;

export type MarkNotificationReadMutation = {
  __typename?: 'Mutation';
  markNotificationRead: {
    __typename?: 'Notification';
    id: string;
    readAt?: any | null;
  };
};

export type RemoveAddressMutationVariables = Exact<{
  id: Scalars['ID'];
  otp?: InputMaybe<OneTimePasswordInput>;
}>;

export type RemoveAddressMutation = {
  __typename?: 'Mutation';
  removeAddress: boolean;
};

export type RemoveSecurityKeyMutationVariables = Exact<{
  securityKeyId: Scalars['ID'];
  otp?: InputMaybe<OneTimePasswordInput>;
}>;

export type RemoveSecurityKeyMutation = {
  __typename?: 'Mutation';
  removeSecurityKey: boolean;
};

export type SecurityKeyResultMutationVariables = Exact<{
  type: Scalars['String'];
  response: Scalars['String'];
  name: Scalars['String'];
}>;

export type SecurityKeyResultMutation = {
  __typename?: 'Mutation';
  securityKeyResult: boolean;
};

export type SetPrimaryAddressMutationVariables = Exact<{
  addressId: Scalars['ID'];
}>;

export type SetPrimaryAddressMutation = {
  __typename?: 'Mutation';
  setPrimaryAddress: {
    __typename?: 'UserAddress';
    id: string;
    currency: Currency;
    name?: string | null;
    address: string;
    balance?: number | null;
    solanaBalance?: number | null;
    createdAt: any;
    isPrimary: boolean;
  };
};

export type SetupMultiFactorMutationVariables = Exact<{ [key: string]: never }>;

export type SetupMultiFactorMutation = {
  __typename?: 'Mutation';
  setupMultiFactor?: {
    __typename?: 'SetupMultifactorResponse';
    keyUri?: string | null;
    mnemonicWords?: Array<string> | null;
    success: boolean;
  } | null;
};

export type TerminateAllUserSessionsMutationVariables = Exact<{
  [key: string]: never;
}>;

export type TerminateAllUserSessionsMutation = {
  __typename?: 'Mutation';
  terminateAllUserSessions: number;
};

export type TerminateUserSessionMutationVariables = Exact<{
  id: Scalars['ID'];
}>;

export type TerminateUserSessionMutation = {
  __typename?: 'Mutation';
  terminateUserSession: boolean;
};

export type UpdateEmailAddressMutationVariables = Exact<{
  emailAddress: Scalars['String'];
  otp?: InputMaybe<OneTimePasswordInput>;
}>;

export type UpdateEmailAddressMutation = {
  __typename?: 'Mutation';
  updateEmailAddress: boolean;
};

export type UpdatePhoneNumberMutationVariables = Exact<{
  phoneNumber: Scalars['String'];
  otp?: InputMaybe<OneTimePasswordInput>;
}>;

export type UpdatePhoneNumberMutation = {
  __typename?: 'Mutation';
  updatePhoneNumber: boolean;
};

export type UpdateProfileMutationVariables = Exact<{
  input: UpdateProfileInput;
}>;

export type UpdateProfileMutation = {
  __typename?: 'Mutation';
  updateProfile: {
    __typename?: 'User';
    id: string;
    fullName?: string | null;
    avatarUrl?: string | null;
    nationalId: string;
    emailAddress?: string | null;
    emailAddressVerified?: boolean | null;
    phoneNumber?: string | null;
    termsAgreedAt?: any | null;
    multiFactorMethods: Array<MultiFactorType>;
    multiFactorWithdrawal: boolean;
    securityKeyRequiresOTP: boolean;
    securityKeyLogin: boolean;
    securityOnboardedAt?: any | null;
    frozenUntil?: any | null;
    kycStatus: KycStatus;
    preferences: {
      __typename?: 'Preferences';
      tokenDepositAction: string;
      fiatDepositAction: string;
    };
  };
};

export type UpdateSecurityPreferencesMutationVariables = Exact<{
  otp?: InputMaybe<OneTimePasswordInput>;
  types?: InputMaybe<Array<MultiFactorType> | MultiFactorType>;
  preferences?: InputMaybe<SecurityPreferencesInput>;
}>;

export type UpdateSecurityPreferencesMutation = {
  __typename?: 'Mutation';
  updateSecurityPreferences: {
    __typename?: 'User';
    id: string;
    multiFactorMethods: Array<MultiFactorType>;
    multiFactorWithdrawal: boolean;
    securityKeyRequiresOTP: boolean;
    securityKeyLogin: boolean;
    securityOnboardedAt?: any | null;
  };
};

export type VerifyMultiFactorMutationVariables = Exact<{
  code: Scalars['String'];
}>;

export type VerifyMultiFactorMutation = {
  __typename?: 'Mutation';
  verifyMultiFactor: boolean;
};

export type WithdrawMutationVariables = Exact<{
  amount: Scalars['Float'];
  walletId: Scalars['ID'];
  addressId: Scalars['ID'];
  requestLamports?: InputMaybe<Scalars['Boolean']>;
}>;

export type WithdrawMutation = {
  __typename?: 'Mutation';
  withdraw?: {
    __typename?: 'Transaction';
    id: string;
    currency: string;
    type: TransactionType;
    status: TransactionStatus;
    dstAddress?: string | null;
    srcAddress?: string | null;
    statusMessage?: string | null;
    externalTransactionId?: string | null;
    internalTransactionId?: string | null;
    feeTransactionId?: string | null;
    description?: string | null;
    amount: number;
    fee: number;
    createdAt: any;
    processedAt?: any | null;
    settledAt?: any | null;
  } | null;
};

export type ActivityQueryVariables = Exact<{ [key: string]: never }>;

export type ActivityQuery = {
  __typename?: 'Query';
  me: {
    __typename?: 'User';
    id: string;
    sessions: Array<{
      __typename?: 'UserSession';
      id: string;
      userAgent?: string | null;
      ip?: string | null;
      createdAt: any;
      updatedAt: any;
    }>;
  };
};

export type AddressBookQueryVariables = Exact<{ [key: string]: never }>;

export type AddressBookQuery = {
  __typename?: 'Query';
  me: {
    __typename?: 'User';
    id: string;
    addressBook: Array<{
      __typename?: 'UserAddress';
      id: string;
      currency: Currency;
      name?: string | null;
      address: string;
      balance?: number | null;
      solanaBalance?: number | null;
      createdAt: any;
      isPrimary: boolean;
    }>;
  };
};

export type DashboardQueryVariables = Exact<{
  cursor?: InputMaybe<Scalars['String']>;
}>;

export type DashboardQuery = {
  __typename?: 'Query';
  me: {
    __typename?: 'User';
    id: string;
    wallets: Array<{
      __typename?: 'Wallet';
      id: string;
      currency: Currency;
      currentBalance: number;
      availableBalance: number;
      publicKey?: string | null;
    }>;
    transactions: {
      __typename?: 'TransactionConnection';
      pageInfo: {
        __typename?: 'PageInfo';
        endCursor?: string | null;
        hasNextPage: boolean;
      };
      edges: Array<{
        __typename?: 'TransactionEdge';
        cursor: string;
        node: {
          __typename?: 'Transaction';
          id: string;
          currency: string;
          type: TransactionType;
          status: TransactionStatus;
          dstAddress?: string | null;
          srcAddress?: string | null;
          statusMessage?: string | null;
          externalTransactionId?: string | null;
          internalTransactionId?: string | null;
          feeTransactionId?: string | null;
          description?: string | null;
          amount: number;
          fee: number;
          createdAt: any;
          processedAt?: any | null;
          settledAt?: any | null;
        };
      }>;
    };
  };
};

export type ExchangeQueryVariables = Exact<{ [key: string]: never }>;

export type ExchangeQuery = {
  __typename?: 'Query';
  priceTable: any;
  me: {
    __typename?: 'User';
    wallets: Array<{
      __typename?: 'Wallet';
      id: string;
      currency: Currency;
      currentBalance: number;
      availableBalance: number;
      publicKey?: string | null;
    }>;
  };
};

export type ProfileQueryVariables = Exact<{ [key: string]: never }>;

export type ProfileQuery = {
  __typename?: 'Query';
  priceTable: any;
  me: {
    __typename?: 'User';
    unreadNotificationsCount: number;
    id: string;
    fullName?: string | null;
    avatarUrl?: string | null;
    nationalId: string;
    emailAddress?: string | null;
    emailAddressVerified?: boolean | null;
    phoneNumber?: string | null;
    termsAgreedAt?: any | null;
    multiFactorMethods: Array<MultiFactorType>;
    multiFactorWithdrawal: boolean;
    securityKeyRequiresOTP: boolean;
    securityKeyLogin: boolean;
    securityOnboardedAt?: any | null;
    frozenUntil?: any | null;
    kycStatus: KycStatus;
    notifications: {
      __typename?: 'NotificationConnection';
      pageInfo: {
        __typename?: 'PageInfo';
        hasNextPage: boolean;
        endCursor?: string | null;
      };
      edges: Array<{
        __typename?: 'NotificationEdge';
        cursor: string;
        node: {
          __typename?: 'Notification';
          id: string;
          title: string;
          body: string;
          actionLabel?: string | null;
          actionUrl?: string | null;
          readAt?: any | null;
          createdAt: any;
        };
      }>;
    };
    addressBook: Array<{
      __typename?: 'UserAddress';
      id: string;
      currency: Currency;
      name?: string | null;
      address: string;
      balance?: number | null;
      solanaBalance?: number | null;
      createdAt: any;
      isPrimary: boolean;
    }>;
    preferences: {
      __typename?: 'Preferences';
      tokenDepositAction: string;
      fiatDepositAction: string;
    };
  };
};

export type SecurityQueryVariables = Exact<{ [key: string]: never }>;

export type SecurityQuery = {
  __typename?: 'Query';
  me: {
    __typename?: 'User';
    id: string;
    emailAddress?: string | null;
    emailAddressVerified?: boolean | null;
    phoneNumber?: string | null;
    multiFactorMethods: Array<MultiFactorType>;
    multiFactorWithdrawal: boolean;
    securityKeyRequiresOTP: boolean;
    securityKeyLogin: boolean;
    auths: Array<{
      __typename?: 'UserAuth';
      id: string;
      name?: string | null;
      hardware?: string | null;
      type: MultiFactorType;
      transports: Array<string>;
      createdAt: any;
      updatedAt: any;
    }>;
  };
};

export type TransactionQueryVariables = Exact<{
  transactionId: Scalars['ID'];
}>;

export type TransactionQuery = {
  __typename?: 'Query';
  Transaction: {
    __typename?: 'Transaction';
    id: string;
    externalTransactionId?: string | null;
    amount: number;
    fee: number;
    createdAt: any;
    status: TransactionStatus;
    currency: string;
    type: TransactionType;
    dstAddress?: string | null;
    requestLamports: boolean;
    lamportsInFiat: number;
  };
};

export type WalletQueryVariables = Exact<{
  walletId: Scalars['ID'];
}>;

export type WalletQuery = {
  __typename?: 'Query';
  Wallet: {
    __typename?: 'Wallet';
    id: string;
    currency: Currency;
    currentBalance: number;
    availableBalance: number;
    publicKey?: string | null;
    transactions: Array<{
      __typename?: 'Transaction';
      id: string;
      currency: string;
      type: TransactionType;
      status: TransactionStatus;
      dstAddress?: string | null;
      srcAddress?: string | null;
      statusMessage?: string | null;
      externalTransactionId?: string | null;
      internalTransactionId?: string | null;
      feeTransactionId?: string | null;
      description?: string | null;
      amount: number;
      fee: number;
      createdAt: any;
      processedAt?: any | null;
      settledAt?: any | null;
    }>;
  };
};

export type WalletsQueryVariables = Exact<{ [key: string]: never }>;

export type WalletsQuery = {
  __typename?: 'Query';
  priceTable: any;
  me: {
    __typename?: 'User';
    wallets: Array<{
      __typename?: 'Wallet';
      id: string;
      currency: Currency;
      currentBalance: number;
      availableBalance: number;
      publicKey?: string | null;
    }>;
    addressBook: Array<{
      __typename?: 'UserAddress';
      id: string;
      currency: Currency;
      name?: string | null;
      address: string;
      balance?: number | null;
      solanaBalance?: number | null;
      createdAt: any;
      isPrimary: boolean;
    }>;
  };
};

export type NewNotificationSubscriptionVariables = Exact<{
  [key: string]: never;
}>;

export type NewNotificationSubscription = {
  __typename?: 'Subscription';
  newNotification?: {
    __typename?: 'Notification';
    id: string;
    title: string;
    body: string;
    actionLabel?: string | null;
    actionUrl?: string | null;
    readAt?: any | null;
    createdAt: any;
  } | null;
};

export type TransactionChangesSubscriptionVariables = Exact<{
  [key: string]: never;
}>;

export type TransactionChangesSubscription = {
  __typename?: 'Subscription';
  transactionChanges?: {
    __typename?: 'Transaction';
    id: string;
    currency: string;
    type: TransactionType;
    status: TransactionStatus;
    dstAddress?: string | null;
    srcAddress?: string | null;
    statusMessage?: string | null;
    externalTransactionId?: string | null;
    internalTransactionId?: string | null;
    feeTransactionId?: string | null;
    description?: string | null;
    amount: number;
    fee: number;
    createdAt: any;
    processedAt?: any | null;
    settledAt?: any | null;
    wallet: {
      __typename?: 'Wallet';
      id: string;
      currency: Currency;
      currentBalance: number;
      availableBalance: number;
      publicKey?: string | null;
    };
  } | null;
};

export const NotificationFragmentFragmentDoc = gql`
  fragment NotificationFragment on Notification {
    id
    title
    body
    actionLabel
    actionUrl
    readAt
    createdAt
  }
`;
export const TransactionFragmentFragmentDoc = gql`
  fragment TransactionFragment on Transaction {
    id
    currency
    type
    status
    dstAddress
    srcAddress
    statusMessage
    externalTransactionId
    internalTransactionId
    feeTransactionId
    description
    amount
    fee
    createdAt
    processedAt
    settledAt
  }
`;
export const UserAddressFragmentFragmentDoc = gql`
  fragment UserAddressFragment on UserAddress {
    id
    currency
    name
    address
    balance
    solanaBalance
    createdAt
    isPrimary
  }
`;
export const UserAuthFragmentFragmentDoc = gql`
  fragment UserAuthFragment on UserAuth {
    id
    name
    hardware
    type
    transports
    createdAt
    updatedAt
  }
`;
export const UserSessionFragmentFragmentDoc = gql`
  fragment UserSessionFragment on UserSession {
    id
    userAgent
    ip
    createdAt
    updatedAt
  }
`;
export const UserFragmentFragmentDoc = gql`
  fragment UserFragment on User {
    id
    fullName
    avatarUrl
    nationalId
    emailAddress
    emailAddressVerified
    phoneNumber
    termsAgreedAt
    multiFactorMethods
    multiFactorWithdrawal
    securityKeyRequiresOTP
    securityKeyLogin
    securityOnboardedAt
    frozenUntil
    kycStatus
    preferences {
      tokenDepositAction
      fiatDepositAction
    }
  }
`;
export const WalletFragmentFragmentDoc = gql`
  fragment WalletFragment on Wallet {
    id
    currency
    currentBalance
    availableBalance
    publicKey
  }
`;
export const CompleteSignupDocument = gql`
  mutation completeSignup($email: String!) {
    completeSignup(email: $email) {
      id
      termsAgreedAt
      emailAddress
      emailAddressVerified
    }
  }
`;
export type CompleteSignupMutationFn = Apollo.MutationFunction<
  CompleteSignupMutation,
  CompleteSignupMutationVariables
>;

/**
 * __useCompleteSignupMutation__
 *
 * To run a mutation, you first call `useCompleteSignupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCompleteSignupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [completeSignupMutation, { data, loading, error }] = useCompleteSignupMutation({
 *   variables: {
 *      email: // value for 'email'
 *   },
 * });
 */
export function useCompleteSignupMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CompleteSignupMutation,
    CompleteSignupMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CompleteSignupMutation,
    CompleteSignupMutationVariables
  >(CompleteSignupDocument, options);
}
export type CompleteSignupMutationHookResult = ReturnType<
  typeof useCompleteSignupMutation
>;
export type CompleteSignupMutationResult =
  Apollo.MutationResult<CompleteSignupMutation>;
export type CompleteSignupMutationOptions = Apollo.BaseMutationOptions<
  CompleteSignupMutation,
  CompleteSignupMutationVariables
>;
export const CompleteSurveyDocument = gql`
  mutation completeSurvey($type: KycStatus!, $answers: JSON!) {
    completeSurvey(type: $type, answers: $answers) {
      id
      kycStatus
    }
  }
`;
export type CompleteSurveyMutationFn = Apollo.MutationFunction<
  CompleteSurveyMutation,
  CompleteSurveyMutationVariables
>;

/**
 * __useCompleteSurveyMutation__
 *
 * To run a mutation, you first call `useCompleteSurveyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCompleteSurveyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [completeSurveyMutation, { data, loading, error }] = useCompleteSurveyMutation({
 *   variables: {
 *      type: // value for 'type'
 *      answers: // value for 'answers'
 *   },
 * });
 */
export function useCompleteSurveyMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CompleteSurveyMutation,
    CompleteSurveyMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CompleteSurveyMutation,
    CompleteSurveyMutationVariables
  >(CompleteSurveyDocument, options);
}
export type CompleteSurveyMutationHookResult = ReturnType<
  typeof useCompleteSurveyMutation
>;
export type CompleteSurveyMutationResult =
  Apollo.MutationResult<CompleteSurveyMutation>;
export type CompleteSurveyMutationOptions = Apollo.BaseMutationOptions<
  CompleteSurveyMutation,
  CompleteSurveyMutationVariables
>;
export const CreateAddressDocument = gql`
  mutation createAddress(
    $input: CreateAddressInput!
    $otp: OneTimePasswordInput
  ) {
    createAddress(input: $input, otp: $otp) {
      ...UserAddressFragment
    }
  }
  ${UserAddressFragmentFragmentDoc}
`;
export type CreateAddressMutationFn = Apollo.MutationFunction<
  CreateAddressMutation,
  CreateAddressMutationVariables
>;

/**
 * __useCreateAddressMutation__
 *
 * To run a mutation, you first call `useCreateAddressMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateAddressMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createAddressMutation, { data, loading, error }] = useCreateAddressMutation({
 *   variables: {
 *      input: // value for 'input'
 *      otp: // value for 'otp'
 *   },
 * });
 */
export function useCreateAddressMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateAddressMutation,
    CreateAddressMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateAddressMutation,
    CreateAddressMutationVariables
  >(CreateAddressDocument, options);
}
export type CreateAddressMutationHookResult = ReturnType<
  typeof useCreateAddressMutation
>;
export type CreateAddressMutationResult =
  Apollo.MutationResult<CreateAddressMutation>;
export type CreateAddressMutationOptions = Apollo.BaseMutationOptions<
  CreateAddressMutation,
  CreateAddressMutationVariables
>;
export const DoExchangeDocument = gql`
  mutation doExchange($amount: Float!, $dstWalletId: ID!, $srcWalletId: ID!) {
    exchange(
      amount: $amount
      dstWalletId: $dstWalletId
      srcWalletId: $srcWalletId
    ) {
      ...TransactionFragment
      dstWallet {
        ...WalletFragment
      }
    }
  }
  ${TransactionFragmentFragmentDoc}
  ${WalletFragmentFragmentDoc}
`;
export type DoExchangeMutationFn = Apollo.MutationFunction<
  DoExchangeMutation,
  DoExchangeMutationVariables
>;

/**
 * __useDoExchangeMutation__
 *
 * To run a mutation, you first call `useDoExchangeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDoExchangeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [doExchangeMutation, { data, loading, error }] = useDoExchangeMutation({
 *   variables: {
 *      amount: // value for 'amount'
 *      dstWalletId: // value for 'dstWalletId'
 *      srcWalletId: // value for 'srcWalletId'
 *   },
 * });
 */
export function useDoExchangeMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DoExchangeMutation,
    DoExchangeMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DoExchangeMutation, DoExchangeMutationVariables>(
    DoExchangeDocument,
    options
  );
}
export type DoExchangeMutationHookResult = ReturnType<
  typeof useDoExchangeMutation
>;
export type DoExchangeMutationResult =
  Apollo.MutationResult<DoExchangeMutation>;
export type DoExchangeMutationOptions = Apollo.BaseMutationOptions<
  DoExchangeMutation,
  DoExchangeMutationVariables
>;
export const MarkNotificationReadDocument = gql`
  mutation markNotificationRead($id: ID!) {
    markNotificationRead(id: $id) {
      id
      readAt
    }
  }
`;
export type MarkNotificationReadMutationFn = Apollo.MutationFunction<
  MarkNotificationReadMutation,
  MarkNotificationReadMutationVariables
>;

/**
 * __useMarkNotificationReadMutation__
 *
 * To run a mutation, you first call `useMarkNotificationReadMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMarkNotificationReadMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [markNotificationReadMutation, { data, loading, error }] = useMarkNotificationReadMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useMarkNotificationReadMutation(
  baseOptions?: Apollo.MutationHookOptions<
    MarkNotificationReadMutation,
    MarkNotificationReadMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    MarkNotificationReadMutation,
    MarkNotificationReadMutationVariables
  >(MarkNotificationReadDocument, options);
}
export type MarkNotificationReadMutationHookResult = ReturnType<
  typeof useMarkNotificationReadMutation
>;
export type MarkNotificationReadMutationResult =
  Apollo.MutationResult<MarkNotificationReadMutation>;
export type MarkNotificationReadMutationOptions = Apollo.BaseMutationOptions<
  MarkNotificationReadMutation,
  MarkNotificationReadMutationVariables
>;
export const RemoveAddressDocument = gql`
  mutation removeAddress($id: ID!, $otp: OneTimePasswordInput) {
    removeAddress(addressId: $id, otp: $otp)
  }
`;
export type RemoveAddressMutationFn = Apollo.MutationFunction<
  RemoveAddressMutation,
  RemoveAddressMutationVariables
>;

/**
 * __useRemoveAddressMutation__
 *
 * To run a mutation, you first call `useRemoveAddressMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveAddressMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeAddressMutation, { data, loading, error }] = useRemoveAddressMutation({
 *   variables: {
 *      id: // value for 'id'
 *      otp: // value for 'otp'
 *   },
 * });
 */
export function useRemoveAddressMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RemoveAddressMutation,
    RemoveAddressMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    RemoveAddressMutation,
    RemoveAddressMutationVariables
  >(RemoveAddressDocument, options);
}
export type RemoveAddressMutationHookResult = ReturnType<
  typeof useRemoveAddressMutation
>;
export type RemoveAddressMutationResult =
  Apollo.MutationResult<RemoveAddressMutation>;
export type RemoveAddressMutationOptions = Apollo.BaseMutationOptions<
  RemoveAddressMutation,
  RemoveAddressMutationVariables
>;
export const RemoveSecurityKeyDocument = gql`
  mutation removeSecurityKey($securityKeyId: ID!, $otp: OneTimePasswordInput) {
    removeSecurityKey(id: $securityKeyId, otp: $otp)
  }
`;
export type RemoveSecurityKeyMutationFn = Apollo.MutationFunction<
  RemoveSecurityKeyMutation,
  RemoveSecurityKeyMutationVariables
>;

/**
 * __useRemoveSecurityKeyMutation__
 *
 * To run a mutation, you first call `useRemoveSecurityKeyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveSecurityKeyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeSecurityKeyMutation, { data, loading, error }] = useRemoveSecurityKeyMutation({
 *   variables: {
 *      securityKeyId: // value for 'securityKeyId'
 *      otp: // value for 'otp'
 *   },
 * });
 */
export function useRemoveSecurityKeyMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RemoveSecurityKeyMutation,
    RemoveSecurityKeyMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    RemoveSecurityKeyMutation,
    RemoveSecurityKeyMutationVariables
  >(RemoveSecurityKeyDocument, options);
}
export type RemoveSecurityKeyMutationHookResult = ReturnType<
  typeof useRemoveSecurityKeyMutation
>;
export type RemoveSecurityKeyMutationResult =
  Apollo.MutationResult<RemoveSecurityKeyMutation>;
export type RemoveSecurityKeyMutationOptions = Apollo.BaseMutationOptions<
  RemoveSecurityKeyMutation,
  RemoveSecurityKeyMutationVariables
>;
export const SecurityKeyResultDocument = gql`
  mutation securityKeyResult(
    $type: String!
    $response: String!
    $name: String!
  ) {
    securityKeyResult(type: $type, response: $response, name: $name)
  }
`;
export type SecurityKeyResultMutationFn = Apollo.MutationFunction<
  SecurityKeyResultMutation,
  SecurityKeyResultMutationVariables
>;

/**
 * __useSecurityKeyResultMutation__
 *
 * To run a mutation, you first call `useSecurityKeyResultMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSecurityKeyResultMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [securityKeyResultMutation, { data, loading, error }] = useSecurityKeyResultMutation({
 *   variables: {
 *      type: // value for 'type'
 *      response: // value for 'response'
 *      name: // value for 'name'
 *   },
 * });
 */
export function useSecurityKeyResultMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SecurityKeyResultMutation,
    SecurityKeyResultMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    SecurityKeyResultMutation,
    SecurityKeyResultMutationVariables
  >(SecurityKeyResultDocument, options);
}
export type SecurityKeyResultMutationHookResult = ReturnType<
  typeof useSecurityKeyResultMutation
>;
export type SecurityKeyResultMutationResult =
  Apollo.MutationResult<SecurityKeyResultMutation>;
export type SecurityKeyResultMutationOptions = Apollo.BaseMutationOptions<
  SecurityKeyResultMutation,
  SecurityKeyResultMutationVariables
>;
export const SetPrimaryAddressDocument = gql`
  mutation SetPrimaryAddress($addressId: ID!) {
    setPrimaryAddress(addressId: $addressId) {
      ...UserAddressFragment
    }
  }
  ${UserAddressFragmentFragmentDoc}
`;
export type SetPrimaryAddressMutationFn = Apollo.MutationFunction<
  SetPrimaryAddressMutation,
  SetPrimaryAddressMutationVariables
>;

/**
 * __useSetPrimaryAddressMutation__
 *
 * To run a mutation, you first call `useSetPrimaryAddressMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetPrimaryAddressMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setPrimaryAddressMutation, { data, loading, error }] = useSetPrimaryAddressMutation({
 *   variables: {
 *      addressId: // value for 'addressId'
 *   },
 * });
 */
export function useSetPrimaryAddressMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SetPrimaryAddressMutation,
    SetPrimaryAddressMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    SetPrimaryAddressMutation,
    SetPrimaryAddressMutationVariables
  >(SetPrimaryAddressDocument, options);
}
export type SetPrimaryAddressMutationHookResult = ReturnType<
  typeof useSetPrimaryAddressMutation
>;
export type SetPrimaryAddressMutationResult =
  Apollo.MutationResult<SetPrimaryAddressMutation>;
export type SetPrimaryAddressMutationOptions = Apollo.BaseMutationOptions<
  SetPrimaryAddressMutation,
  SetPrimaryAddressMutationVariables
>;
export const SetupMultiFactorDocument = gql`
  mutation setupMultiFactor {
    setupMultiFactor {
      keyUri
      mnemonicWords
      success
    }
  }
`;
export type SetupMultiFactorMutationFn = Apollo.MutationFunction<
  SetupMultiFactorMutation,
  SetupMultiFactorMutationVariables
>;

/**
 * __useSetupMultiFactorMutation__
 *
 * To run a mutation, you first call `useSetupMultiFactorMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetupMultiFactorMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setupMultiFactorMutation, { data, loading, error }] = useSetupMultiFactorMutation({
 *   variables: {
 *   },
 * });
 */
export function useSetupMultiFactorMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SetupMultiFactorMutation,
    SetupMultiFactorMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    SetupMultiFactorMutation,
    SetupMultiFactorMutationVariables
  >(SetupMultiFactorDocument, options);
}
export type SetupMultiFactorMutationHookResult = ReturnType<
  typeof useSetupMultiFactorMutation
>;
export type SetupMultiFactorMutationResult =
  Apollo.MutationResult<SetupMultiFactorMutation>;
export type SetupMultiFactorMutationOptions = Apollo.BaseMutationOptions<
  SetupMultiFactorMutation,
  SetupMultiFactorMutationVariables
>;
export const TerminateAllUserSessionsDocument = gql`
  mutation terminateAllUserSessions {
    terminateAllUserSessions
  }
`;
export type TerminateAllUserSessionsMutationFn = Apollo.MutationFunction<
  TerminateAllUserSessionsMutation,
  TerminateAllUserSessionsMutationVariables
>;

/**
 * __useTerminateAllUserSessionsMutation__
 *
 * To run a mutation, you first call `useTerminateAllUserSessionsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTerminateAllUserSessionsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [terminateAllUserSessionsMutation, { data, loading, error }] = useTerminateAllUserSessionsMutation({
 *   variables: {
 *   },
 * });
 */
export function useTerminateAllUserSessionsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    TerminateAllUserSessionsMutation,
    TerminateAllUserSessionsMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    TerminateAllUserSessionsMutation,
    TerminateAllUserSessionsMutationVariables
  >(TerminateAllUserSessionsDocument, options);
}
export type TerminateAllUserSessionsMutationHookResult = ReturnType<
  typeof useTerminateAllUserSessionsMutation
>;
export type TerminateAllUserSessionsMutationResult =
  Apollo.MutationResult<TerminateAllUserSessionsMutation>;
export type TerminateAllUserSessionsMutationOptions =
  Apollo.BaseMutationOptions<
    TerminateAllUserSessionsMutation,
    TerminateAllUserSessionsMutationVariables
  >;
export const TerminateUserSessionDocument = gql`
  mutation terminateUserSession($id: ID!) {
    terminateUserSession(id: $id)
  }
`;
export type TerminateUserSessionMutationFn = Apollo.MutationFunction<
  TerminateUserSessionMutation,
  TerminateUserSessionMutationVariables
>;

/**
 * __useTerminateUserSessionMutation__
 *
 * To run a mutation, you first call `useTerminateUserSessionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTerminateUserSessionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [terminateUserSessionMutation, { data, loading, error }] = useTerminateUserSessionMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useTerminateUserSessionMutation(
  baseOptions?: Apollo.MutationHookOptions<
    TerminateUserSessionMutation,
    TerminateUserSessionMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    TerminateUserSessionMutation,
    TerminateUserSessionMutationVariables
  >(TerminateUserSessionDocument, options);
}
export type TerminateUserSessionMutationHookResult = ReturnType<
  typeof useTerminateUserSessionMutation
>;
export type TerminateUserSessionMutationResult =
  Apollo.MutationResult<TerminateUserSessionMutation>;
export type TerminateUserSessionMutationOptions = Apollo.BaseMutationOptions<
  TerminateUserSessionMutation,
  TerminateUserSessionMutationVariables
>;
export const UpdateEmailAddressDocument = gql`
  mutation updateEmailAddress(
    $emailAddress: String!
    $otp: OneTimePasswordInput
  ) {
    updateEmailAddress(emailAddress: $emailAddress, otp: $otp)
  }
`;
export type UpdateEmailAddressMutationFn = Apollo.MutationFunction<
  UpdateEmailAddressMutation,
  UpdateEmailAddressMutationVariables
>;

/**
 * __useUpdateEmailAddressMutation__
 *
 * To run a mutation, you first call `useUpdateEmailAddressMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateEmailAddressMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateEmailAddressMutation, { data, loading, error }] = useUpdateEmailAddressMutation({
 *   variables: {
 *      emailAddress: // value for 'emailAddress'
 *      otp: // value for 'otp'
 *   },
 * });
 */
export function useUpdateEmailAddressMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateEmailAddressMutation,
    UpdateEmailAddressMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateEmailAddressMutation,
    UpdateEmailAddressMutationVariables
  >(UpdateEmailAddressDocument, options);
}
export type UpdateEmailAddressMutationHookResult = ReturnType<
  typeof useUpdateEmailAddressMutation
>;
export type UpdateEmailAddressMutationResult =
  Apollo.MutationResult<UpdateEmailAddressMutation>;
export type UpdateEmailAddressMutationOptions = Apollo.BaseMutationOptions<
  UpdateEmailAddressMutation,
  UpdateEmailAddressMutationVariables
>;
export const UpdatePhoneNumberDocument = gql`
  mutation updatePhoneNumber(
    $phoneNumber: String!
    $otp: OneTimePasswordInput
  ) {
    updatePhoneNumber(phoneNumber: $phoneNumber, otp: $otp)
  }
`;
export type UpdatePhoneNumberMutationFn = Apollo.MutationFunction<
  UpdatePhoneNumberMutation,
  UpdatePhoneNumberMutationVariables
>;

/**
 * __useUpdatePhoneNumberMutation__
 *
 * To run a mutation, you first call `useUpdatePhoneNumberMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePhoneNumberMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePhoneNumberMutation, { data, loading, error }] = useUpdatePhoneNumberMutation({
 *   variables: {
 *      phoneNumber: // value for 'phoneNumber'
 *      otp: // value for 'otp'
 *   },
 * });
 */
export function useUpdatePhoneNumberMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdatePhoneNumberMutation,
    UpdatePhoneNumberMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdatePhoneNumberMutation,
    UpdatePhoneNumberMutationVariables
  >(UpdatePhoneNumberDocument, options);
}
export type UpdatePhoneNumberMutationHookResult = ReturnType<
  typeof useUpdatePhoneNumberMutation
>;
export type UpdatePhoneNumberMutationResult =
  Apollo.MutationResult<UpdatePhoneNumberMutation>;
export type UpdatePhoneNumberMutationOptions = Apollo.BaseMutationOptions<
  UpdatePhoneNumberMutation,
  UpdatePhoneNumberMutationVariables
>;
export const UpdateProfileDocument = gql`
  mutation UpdateProfile($input: UpdateProfileInput!) {
    updateProfile(input: $input) {
      ...UserFragment
    }
  }
  ${UserFragmentFragmentDoc}
`;
export type UpdateProfileMutationFn = Apollo.MutationFunction<
  UpdateProfileMutation,
  UpdateProfileMutationVariables
>;

/**
 * __useUpdateProfileMutation__
 *
 * To run a mutation, you first call `useUpdateProfileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateProfileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateProfileMutation, { data, loading, error }] = useUpdateProfileMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateProfileMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateProfileMutation,
    UpdateProfileMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateProfileMutation,
    UpdateProfileMutationVariables
  >(UpdateProfileDocument, options);
}
export type UpdateProfileMutationHookResult = ReturnType<
  typeof useUpdateProfileMutation
>;
export type UpdateProfileMutationResult =
  Apollo.MutationResult<UpdateProfileMutation>;
export type UpdateProfileMutationOptions = Apollo.BaseMutationOptions<
  UpdateProfileMutation,
  UpdateProfileMutationVariables
>;
export const UpdateSecurityPreferencesDocument = gql`
  mutation updateSecurityPreferences(
    $otp: OneTimePasswordInput
    $types: [MultiFactorType!]
    $preferences: SecurityPreferencesInput
  ) {
    updateSecurityPreferences(
      otp: $otp
      types: $types
      preferences: $preferences
    ) {
      id
      multiFactorMethods
      multiFactorWithdrawal
      securityKeyRequiresOTP
      securityKeyLogin
      securityOnboardedAt
    }
  }
`;
export type UpdateSecurityPreferencesMutationFn = Apollo.MutationFunction<
  UpdateSecurityPreferencesMutation,
  UpdateSecurityPreferencesMutationVariables
>;

/**
 * __useUpdateSecurityPreferencesMutation__
 *
 * To run a mutation, you first call `useUpdateSecurityPreferencesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateSecurityPreferencesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateSecurityPreferencesMutation, { data, loading, error }] = useUpdateSecurityPreferencesMutation({
 *   variables: {
 *      otp: // value for 'otp'
 *      types: // value for 'types'
 *      preferences: // value for 'preferences'
 *   },
 * });
 */
export function useUpdateSecurityPreferencesMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateSecurityPreferencesMutation,
    UpdateSecurityPreferencesMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateSecurityPreferencesMutation,
    UpdateSecurityPreferencesMutationVariables
  >(UpdateSecurityPreferencesDocument, options);
}
export type UpdateSecurityPreferencesMutationHookResult = ReturnType<
  typeof useUpdateSecurityPreferencesMutation
>;
export type UpdateSecurityPreferencesMutationResult =
  Apollo.MutationResult<UpdateSecurityPreferencesMutation>;
export type UpdateSecurityPreferencesMutationOptions =
  Apollo.BaseMutationOptions<
    UpdateSecurityPreferencesMutation,
    UpdateSecurityPreferencesMutationVariables
  >;
export const VerifyMultiFactorDocument = gql`
  mutation verifyMultiFactor($code: String!) {
    verifyMultiFactor(code: $code)
  }
`;
export type VerifyMultiFactorMutationFn = Apollo.MutationFunction<
  VerifyMultiFactorMutation,
  VerifyMultiFactorMutationVariables
>;

/**
 * __useVerifyMultiFactorMutation__
 *
 * To run a mutation, you first call `useVerifyMultiFactorMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useVerifyMultiFactorMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [verifyMultiFactorMutation, { data, loading, error }] = useVerifyMultiFactorMutation({
 *   variables: {
 *      code: // value for 'code'
 *   },
 * });
 */
export function useVerifyMultiFactorMutation(
  baseOptions?: Apollo.MutationHookOptions<
    VerifyMultiFactorMutation,
    VerifyMultiFactorMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    VerifyMultiFactorMutation,
    VerifyMultiFactorMutationVariables
  >(VerifyMultiFactorDocument, options);
}
export type VerifyMultiFactorMutationHookResult = ReturnType<
  typeof useVerifyMultiFactorMutation
>;
export type VerifyMultiFactorMutationResult =
  Apollo.MutationResult<VerifyMultiFactorMutation>;
export type VerifyMultiFactorMutationOptions = Apollo.BaseMutationOptions<
  VerifyMultiFactorMutation,
  VerifyMultiFactorMutationVariables
>;
export const WithdrawDocument = gql`
  mutation withdraw(
    $amount: Float!
    $walletId: ID!
    $addressId: ID!
    $requestLamports: Boolean
  ) {
    withdraw(
      walletId: $walletId
      addressId: $addressId
      amount: $amount
      requestLamports: $requestLamports
    ) {
      ...TransactionFragment
    }
  }
  ${TransactionFragmentFragmentDoc}
`;
export type WithdrawMutationFn = Apollo.MutationFunction<
  WithdrawMutation,
  WithdrawMutationVariables
>;

/**
 * __useWithdrawMutation__
 *
 * To run a mutation, you first call `useWithdrawMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useWithdrawMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [withdrawMutation, { data, loading, error }] = useWithdrawMutation({
 *   variables: {
 *      amount: // value for 'amount'
 *      walletId: // value for 'walletId'
 *      addressId: // value for 'addressId'
 *      requestLamports: // value for 'requestLamports'
 *   },
 * });
 */
export function useWithdrawMutation(
  baseOptions?: Apollo.MutationHookOptions<
    WithdrawMutation,
    WithdrawMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<WithdrawMutation, WithdrawMutationVariables>(
    WithdrawDocument,
    options
  );
}
export type WithdrawMutationHookResult = ReturnType<typeof useWithdrawMutation>;
export type WithdrawMutationResult = Apollo.MutationResult<WithdrawMutation>;
export type WithdrawMutationOptions = Apollo.BaseMutationOptions<
  WithdrawMutation,
  WithdrawMutationVariables
>;
export const ActivityDocument = gql`
  query activity {
    me {
      id
      sessions {
        ...UserSessionFragment
      }
    }
  }
  ${UserSessionFragmentFragmentDoc}
`;

/**
 * __useActivityQuery__
 *
 * To run a query within a React component, call `useActivityQuery` and pass it any options that fit your needs.
 * When your component renders, `useActivityQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useActivityQuery({
 *   variables: {
 *   },
 * });
 */
export function useActivityQuery(
  baseOptions?: Apollo.QueryHookOptions<ActivityQuery, ActivityQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ActivityQuery, ActivityQueryVariables>(
    ActivityDocument,
    options
  );
}
export function useActivityLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ActivityQuery,
    ActivityQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ActivityQuery, ActivityQueryVariables>(
    ActivityDocument,
    options
  );
}
export type ActivityQueryHookResult = ReturnType<typeof useActivityQuery>;
export type ActivityLazyQueryHookResult = ReturnType<
  typeof useActivityLazyQuery
>;
export type ActivityQueryResult = Apollo.QueryResult<
  ActivityQuery,
  ActivityQueryVariables
>;
export const AddressBookDocument = gql`
  query addressBook {
    me {
      id
      addressBook {
        ...UserAddressFragment
      }
    }
  }
  ${UserAddressFragmentFragmentDoc}
`;

/**
 * __useAddressBookQuery__
 *
 * To run a query within a React component, call `useAddressBookQuery` and pass it any options that fit your needs.
 * When your component renders, `useAddressBookQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAddressBookQuery({
 *   variables: {
 *   },
 * });
 */
export function useAddressBookQuery(
  baseOptions?: Apollo.QueryHookOptions<
    AddressBookQuery,
    AddressBookQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<AddressBookQuery, AddressBookQueryVariables>(
    AddressBookDocument,
    options
  );
}
export function useAddressBookLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AddressBookQuery,
    AddressBookQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<AddressBookQuery, AddressBookQueryVariables>(
    AddressBookDocument,
    options
  );
}
export type AddressBookQueryHookResult = ReturnType<typeof useAddressBookQuery>;
export type AddressBookLazyQueryHookResult = ReturnType<
  typeof useAddressBookLazyQuery
>;
export type AddressBookQueryResult = Apollo.QueryResult<
  AddressBookQuery,
  AddressBookQueryVariables
>;
export const DashboardDocument = gql`
  query dashboard($cursor: String) {
    me {
      id
      wallets {
        ...WalletFragment
      }
      transactions(first: 25, after: $cursor) {
        pageInfo {
          endCursor
          hasNextPage
        }
        edges {
          cursor
          node {
            ...TransactionFragment
          }
        }
      }
    }
  }
  ${WalletFragmentFragmentDoc}
  ${TransactionFragmentFragmentDoc}
`;

/**
 * __useDashboardQuery__
 *
 * To run a query within a React component, call `useDashboardQuery` and pass it any options that fit your needs.
 * When your component renders, `useDashboardQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDashboardQuery({
 *   variables: {
 *      cursor: // value for 'cursor'
 *   },
 * });
 */
export function useDashboardQuery(
  baseOptions?: Apollo.QueryHookOptions<DashboardQuery, DashboardQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<DashboardQuery, DashboardQueryVariables>(
    DashboardDocument,
    options
  );
}
export function useDashboardLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    DashboardQuery,
    DashboardQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<DashboardQuery, DashboardQueryVariables>(
    DashboardDocument,
    options
  );
}
export type DashboardQueryHookResult = ReturnType<typeof useDashboardQuery>;
export type DashboardLazyQueryHookResult = ReturnType<
  typeof useDashboardLazyQuery
>;
export type DashboardQueryResult = Apollo.QueryResult<
  DashboardQuery,
  DashboardQueryVariables
>;
export const ExchangeDocument = gql`
  query exchange {
    me {
      wallets {
        ...WalletFragment
      }
    }
    priceTable
  }
  ${WalletFragmentFragmentDoc}
`;

/**
 * __useExchangeQuery__
 *
 * To run a query within a React component, call `useExchangeQuery` and pass it any options that fit your needs.
 * When your component renders, `useExchangeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useExchangeQuery({
 *   variables: {
 *   },
 * });
 */
export function useExchangeQuery(
  baseOptions?: Apollo.QueryHookOptions<ExchangeQuery, ExchangeQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ExchangeQuery, ExchangeQueryVariables>(
    ExchangeDocument,
    options
  );
}
export function useExchangeLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ExchangeQuery,
    ExchangeQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ExchangeQuery, ExchangeQueryVariables>(
    ExchangeDocument,
    options
  );
}
export type ExchangeQueryHookResult = ReturnType<typeof useExchangeQuery>;
export type ExchangeLazyQueryHookResult = ReturnType<
  typeof useExchangeLazyQuery
>;
export type ExchangeQueryResult = Apollo.QueryResult<
  ExchangeQuery,
  ExchangeQueryVariables
>;
export const ProfileDocument = gql`
  query profile {
    me {
      ...UserFragment
      unreadNotificationsCount
      notifications(first: 5) {
        pageInfo {
          hasNextPage
          endCursor
        }
        edges {
          cursor
          node {
            ...NotificationFragment
          }
        }
      }
      addressBook {
        ...UserAddressFragment
      }
    }
    priceTable
  }
  ${UserFragmentFragmentDoc}
  ${NotificationFragmentFragmentDoc}
  ${UserAddressFragmentFragmentDoc}
`;

/**
 * __useProfileQuery__
 *
 * To run a query within a React component, call `useProfileQuery` and pass it any options that fit your needs.
 * When your component renders, `useProfileQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProfileQuery({
 *   variables: {
 *   },
 * });
 */
export function useProfileQuery(
  baseOptions?: Apollo.QueryHookOptions<ProfileQuery, ProfileQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ProfileQuery, ProfileQueryVariables>(
    ProfileDocument,
    options
  );
}
export function useProfileLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ProfileQuery, ProfileQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ProfileQuery, ProfileQueryVariables>(
    ProfileDocument,
    options
  );
}
export type ProfileQueryHookResult = ReturnType<typeof useProfileQuery>;
export type ProfileLazyQueryHookResult = ReturnType<typeof useProfileLazyQuery>;
export type ProfileQueryResult = Apollo.QueryResult<
  ProfileQuery,
  ProfileQueryVariables
>;
export const SecurityDocument = gql`
  query security {
    me {
      id
      emailAddress
      emailAddressVerified
      phoneNumber
      multiFactorMethods
      multiFactorWithdrawal
      securityKeyRequiresOTP
      securityKeyLogin
      auths {
        ...UserAuthFragment
      }
    }
  }
  ${UserAuthFragmentFragmentDoc}
`;

/**
 * __useSecurityQuery__
 *
 * To run a query within a React component, call `useSecurityQuery` and pass it any options that fit your needs.
 * When your component renders, `useSecurityQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSecurityQuery({
 *   variables: {
 *   },
 * });
 */
export function useSecurityQuery(
  baseOptions?: Apollo.QueryHookOptions<SecurityQuery, SecurityQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<SecurityQuery, SecurityQueryVariables>(
    SecurityDocument,
    options
  );
}
export function useSecurityLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    SecurityQuery,
    SecurityQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<SecurityQuery, SecurityQueryVariables>(
    SecurityDocument,
    options
  );
}
export type SecurityQueryHookResult = ReturnType<typeof useSecurityQuery>;
export type SecurityLazyQueryHookResult = ReturnType<
  typeof useSecurityLazyQuery
>;
export type SecurityQueryResult = Apollo.QueryResult<
  SecurityQuery,
  SecurityQueryVariables
>;
export const TransactionDocument = gql`
  query Transaction($transactionId: ID!) {
    Transaction(transactionId: $transactionId) {
      id
      externalTransactionId
      amount
      fee
      createdAt
      status
      currency
      type
      dstAddress
      requestLamports
      lamportsInFiat
    }
  }
`;

/**
 * __useTransactionQuery__
 *
 * To run a query within a React component, call `useTransactionQuery` and pass it any options that fit your needs.
 * When your component renders, `useTransactionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTransactionQuery({
 *   variables: {
 *      transactionId: // value for 'transactionId'
 *   },
 * });
 */
export function useTransactionQuery(
  baseOptions: Apollo.QueryHookOptions<
    TransactionQuery,
    TransactionQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<TransactionQuery, TransactionQueryVariables>(
    TransactionDocument,
    options
  );
}
export function useTransactionLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    TransactionQuery,
    TransactionQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<TransactionQuery, TransactionQueryVariables>(
    TransactionDocument,
    options
  );
}
export type TransactionQueryHookResult = ReturnType<typeof useTransactionQuery>;
export type TransactionLazyQueryHookResult = ReturnType<
  typeof useTransactionLazyQuery
>;
export type TransactionQueryResult = Apollo.QueryResult<
  TransactionQuery,
  TransactionQueryVariables
>;
export const WalletDocument = gql`
  query wallet($walletId: ID!) {
    Wallet(walletId: $walletId) {
      ...WalletFragment
      transactions {
        ...TransactionFragment
      }
    }
  }
  ${WalletFragmentFragmentDoc}
  ${TransactionFragmentFragmentDoc}
`;

/**
 * __useWalletQuery__
 *
 * To run a query within a React component, call `useWalletQuery` and pass it any options that fit your needs.
 * When your component renders, `useWalletQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useWalletQuery({
 *   variables: {
 *      walletId: // value for 'walletId'
 *   },
 * });
 */
export function useWalletQuery(
  baseOptions: Apollo.QueryHookOptions<WalletQuery, WalletQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<WalletQuery, WalletQueryVariables>(
    WalletDocument,
    options
  );
}
export function useWalletLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<WalletQuery, WalletQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<WalletQuery, WalletQueryVariables>(
    WalletDocument,
    options
  );
}
export type WalletQueryHookResult = ReturnType<typeof useWalletQuery>;
export type WalletLazyQueryHookResult = ReturnType<typeof useWalletLazyQuery>;
export type WalletQueryResult = Apollo.QueryResult<
  WalletQuery,
  WalletQueryVariables
>;
export const WalletsDocument = gql`
  query wallets {
    me {
      wallets {
        ...WalletFragment
      }
      addressBook {
        ...UserAddressFragment
      }
    }
    priceTable
  }
  ${WalletFragmentFragmentDoc}
  ${UserAddressFragmentFragmentDoc}
`;

/**
 * __useWalletsQuery__
 *
 * To run a query within a React component, call `useWalletsQuery` and pass it any options that fit your needs.
 * When your component renders, `useWalletsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useWalletsQuery({
 *   variables: {
 *   },
 * });
 */
export function useWalletsQuery(
  baseOptions?: Apollo.QueryHookOptions<WalletsQuery, WalletsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<WalletsQuery, WalletsQueryVariables>(
    WalletsDocument,
    options
  );
}
export function useWalletsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<WalletsQuery, WalletsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<WalletsQuery, WalletsQueryVariables>(
    WalletsDocument,
    options
  );
}
export type WalletsQueryHookResult = ReturnType<typeof useWalletsQuery>;
export type WalletsLazyQueryHookResult = ReturnType<typeof useWalletsLazyQuery>;
export type WalletsQueryResult = Apollo.QueryResult<
  WalletsQuery,
  WalletsQueryVariables
>;
export const NewNotificationDocument = gql`
  subscription newNotification {
    newNotification {
      ...NotificationFragment
    }
  }
  ${NotificationFragmentFragmentDoc}
`;

/**
 * __useNewNotificationSubscription__
 *
 * To run a query within a React component, call `useNewNotificationSubscription` and pass it any options that fit your needs.
 * When your component renders, `useNewNotificationSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNewNotificationSubscription({
 *   variables: {
 *   },
 * });
 */
export function useNewNotificationSubscription(
  baseOptions?: Apollo.SubscriptionHookOptions<
    NewNotificationSubscription,
    NewNotificationSubscriptionVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSubscription<
    NewNotificationSubscription,
    NewNotificationSubscriptionVariables
  >(NewNotificationDocument, options);
}
export type NewNotificationSubscriptionHookResult = ReturnType<
  typeof useNewNotificationSubscription
>;
export type NewNotificationSubscriptionResult =
  Apollo.SubscriptionResult<NewNotificationSubscription>;
export const TransactionChangesDocument = gql`
  subscription transactionChanges {
    transactionChanges {
      ...TransactionFragment
      wallet {
        ...WalletFragment
      }
    }
  }
  ${TransactionFragmentFragmentDoc}
  ${WalletFragmentFragmentDoc}
`;

/**
 * __useTransactionChangesSubscription__
 *
 * To run a query within a React component, call `useTransactionChangesSubscription` and pass it any options that fit your needs.
 * When your component renders, `useTransactionChangesSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTransactionChangesSubscription({
 *   variables: {
 *   },
 * });
 */
export function useTransactionChangesSubscription(
  baseOptions?: Apollo.SubscriptionHookOptions<
    TransactionChangesSubscription,
    TransactionChangesSubscriptionVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSubscription<
    TransactionChangesSubscription,
    TransactionChangesSubscriptionVariables
  >(TransactionChangesDocument, options);
}
export type TransactionChangesSubscriptionHookResult = ReturnType<
  typeof useTransactionChangesSubscription
>;
export type TransactionChangesSubscriptionResult =
  Apollo.SubscriptionResult<TransactionChangesSubscription>;
