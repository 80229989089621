import { css } from 'styled-components';

export function responseFont(minSize = 16, size = 16, limit = 1200) {
  return (props: { theme: any }) => {
    const { theme } = props;
    minSize = minSize ?? theme.font.sizeMin;
    size = size ?? theme.font.size;
    limit = limit ?? theme.pageLimit({ theme });
    const baseline = theme.customBreakpoints.sm.width;
    const fontMultiplier = (size - minSize) / (limit - baseline);
    const fontBaseline = minSize - fontMultiplier * baseline;

    return css`
      font-size: ${minSize}px;

      @media (min-width: ${theme.customBreakpoints.sm.width}px) {
        font-size: calc(${fontMultiplier} * 100vw + (${fontBaseline}px));
      }

      @media (min-width: ${limit}px) {
        font-size: ${size}px;
      }
    `;
  };
}

export function fit() {
  return css`
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  `;
}

export function sronly() {
  return css`
    position: absolute;
    left: -10000px;
    top: auto;
    width: 1px;
    height: 1px;
    overflow: hidden;
  `;
}

export function linkColor() {
  return css`
    color: #003cff;

    &:visited {
      color: #003cff;
    }

    &:hover {
      opacity: 0.8;
    }
  `;
}
