import React from 'react';
import styled from 'styled-components';

const Host = styled.div`
  display: flex;
  flex-flow: row nowrap;
  align-items: center;

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: stretch;
    padding: 0 20px;
  }
`;

interface NavigationProps {
  children: React.ReactNode;
}
export const Navigation = ({ children }: NavigationProps) => {
  return <Host>{children}</Host>;
};
