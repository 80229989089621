import Link from 'next/link';
import { useRouter } from 'next/router';
import { BsFacebook, BsTelegram, BsTwitter } from 'react-icons/bs';
import { useIntl } from 'react-intl';
import styled from 'styled-components';
import { Footer } from '../../components/footer/footer';
import { MintumLogo } from '../../components/logo/mintum-logo';

const SocialA = styled.a`
  display: inline-block;
  margin-right: 16px;

  color: #000;

  opacity: 1;
  transition: opacity 200ms ease-in-out;

  &:visited {
    color: #000;
  }

  &:hover  {
    opacity: 0.7;
  }
`;

export const FooterContainer = () => {
  const intl = useIntl();
  const router = useRouter();
  return (
    <Footer
      logo={<MintumLogo />}
      navs={[
        <>
          <Link href={!router.asPath.startsWith('/en') ? '/' : '/en'} passHref>
            {intl.formatMessage({ id: 'home', defaultMessage: 'Heim' })}
          </Link>
          <Link
            href={!router.asPath.startsWith('/en') ? '/about' : '/en/about'}
            passHref>
            {intl.formatMessage({ id: 'about-us', defaultMessage: 'Um okkur' })}
          </Link>
          <Link
            href="https://dex.mintum.is/#/market/FvhTW3kMofdmfvC6H2bJLuYysikBkLoS8PVLMFtuYjqW"
            passHref
            target="_blank">
            {intl.formatMessage({ id: 'exchange', defaultMessage: 'Kauphöll' })}
          </Link>
          <Link
            href="https://support.mintum.is/"
            target="_blank"
            rel="norefferer nofollow"
            passHref>
            {intl.formatMessage({ id: 'help', defaultMessage: 'Hjálp' })}
          </Link>
        </>,
        <>
          <Link href="/certificate" passHref>
            {intl.formatMessage({
              id: 'confirmations',
              defaultMessage: 'Staðfestingar',
            })}
          </Link>
          {/* <Link href="/privacy-policy" passHref>
            Persónuvernd
          </Link> */}
          <Link
            href={!router.asPath.startsWith('/en') ? '/terms' : '/en/terms'}
            passHref>
            {intl.formatMessage({
              id: 'terms',
              defaultMessage: 'Skilmálar',
            })}
          </Link>
          <Link href="/tariff" passHref>
            {intl.formatMessage({
              id: 'tariff',
              defaultMessage: 'Gjaldskrá',
            })}
          </Link>
        </>,
      ]}
      info={
        <p>
          Rafmyntasjóður Íslands ehf.
          <br />
          Kt. 431121-1580 <br />
          +354 695-7889
          <br />
          <br />
          Bæjarlind 4
          <br />
          201 Kópavogur
          <br />
          Ísland
        </p>
      }
      socialMedia={
        <>
          <SocialA
            href="https://www.facebook.com/mintumIceland"
            target="_blank"
            rel="noopener noreferrer">
            <BsFacebook size={32} />
          </SocialA>
          <SocialA
            href="https://t.me/mintum_is"
            target="_blank"
            rel="noopener noreferrer">
            <BsTelegram size={32} />
          </SocialA>
          <SocialA
            href="https://twitter.com/mintumiceland"
            target="_blank"
            rel="noopener noreferrer">
            <BsTwitter size={32} />
          </SocialA>
        </>
      }
    />
  );
};
