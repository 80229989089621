import type { FetchResult, Operation } from '@apollo/client/core';
import { ApolloLink, Observable } from '@apollo/client/core';
import { print } from 'graphql';
import type { Client, ClientOptions } from 'graphql-ws';
import { createClient } from 'graphql-ws';

export class WebSocketLink extends ApolloLink {
  private client: Client;

  constructor(options: ClientOptions) {
    super();
    this.client = createClient(options);
  }

  public request(operation: Operation): Observable<FetchResult> {
    return new Observable((sink) =>
      this.client.subscribe<FetchResult>(
        { ...operation, query: print(operation.query) },
        {
          next: sink.next.bind(sink),
          complete: sink.complete.bind(sink),
          error: sink.error.bind(sink),
        }
      )
    );
  }
}
