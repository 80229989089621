import Script from 'next/script';
import { config } from '../config';

type ObjectLiteral = {
  [key: string]: string | number | boolean | ObjectLiteral;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const win: any = process.browser ? window : {};

export const fbq = {
  pageview() {
    win.fbq?.('track', 'PageView');
  },
  event(name: string, options: ObjectLiteral = {}) {
    win.fbq?.('track', name, options);
  },
  script() {
    if (!config.facebookPixelId) {
      return null;
    }
    return (
      <Script
        id="fbq-init"
        strategy="afterInteractive"
        dangerouslySetInnerHTML={{
          __html: `
      !function(f,b,e,v,n,t,s)
      {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
      n.callMethod.apply(n,arguments):n.queue.push(arguments)};
      if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
      n.queue=[];t=b.createElement(e);t.async=!0;
      t.src=v;s=b.getElementsByTagName(e)[0];
      s.parentNode.insertBefore(t,s)}(window, document,'script',
      'https://connect.facebook.net/en_US/fbevents.js');
      fbq('init', ${config.facebookPixelId});
    `,
        }}
      />
    );
  },
  noscript() {
    if (!config.facebookPixelId) {
      return null;
    }
    return (
      <noscript>
        <img
          height="1"
          width="1"
          style={{ display: 'none' }}
          alt="Facebook pixel"
          src={`https://www.facebook.com/tr?id=${config.facebookPixelId}&ev=PageView&noscript=1`}
        />
      </noscript>
    );
  },
};

export const gtag = {
  pageview(url: string) {
    win.gtag?.('config', config.googleTrackingId, {
      page_path: url,
    });
  },
  event({
    action,
    category,
    label,
    value,
  }: {
    action: string;
    category: string;
    label: string;
    value?: number;
  }) {
    win.gtag?.('event', action, {
      event_category: category,
      event_label: label,
      value: value,
    });
  },
  script() {
    if (!config.googleTrackingId) {
      return null;
    }
    return (
      <>
        <Script
          strategy="afterInteractive"
          src={`https://www.googletagmanager.com/gtag/js?id=${config.googleTrackingId}`}
        />
        <Script
          id="gtag-init"
          strategy="afterInteractive"
          dangerouslySetInnerHTML={{
            __html: `
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());
            gtag('config', '${config.googleTrackingId}', {
              page_path: window.location.pathname,
            });
          `,
          }}
        />
      </>
    );
  },
};

export const gist = {
  identify(...args: any) {
    win.gist?.identify(...args);
  },
  track(...args: any) {
    win.gist?.track(...args);
  },
  trackPageView() {
    win.gist?.trackPageView();
  },
  chat(...args: any) {
    win.gist?.chat(...args);
  },
  ...(win.gist || {}),
};
