import { Button } from '@mantine/core';
import { useLocalStorage } from '@mantine/hooks';
import { motion } from 'framer-motion';
import { useEffect, useState } from 'react';
import styled from 'styled-components';
import { bodyXs, h6 } from '../../styles/typography';
import { useRouter } from 'next/router';

const Host = styled(motion.div)`
  position: fixed;
  z-index: 99;
  bottom: ${(props) => props.theme.gutter}px;
  left: ${(props) => props.theme.gutter}px;
  background-color: #000;
  padding: ${(props) => props.theme.gutter}px;

  border-radius: 16px;

  color: #fff;
  max-width: 320px;
`;

const Title = styled.h2`
  margin: 0;
  ${h6};
  margin-bottom: ${(props) => props.theme.gutter / 2}px;
`;

const Copy = styled.p`
  margin: 0;
  ${bodyXs};
  margin-bottom: ${(props) => props.theme.gutter}px;
`;

export const Gdpr = () => {
  const [consent, setConsent] = useLocalStorage<'false' | 'true'>({
    key: '@gdpr.consent',
    defaultValue: 'false',
  });
  const [variant, setVariant] = useState('initial');
  const router = useRouter();
  const isEn = router.asPath.includes('en/');

  useEffect(() => {
    if (consent) {
      setVariant(consent === 'false' ? 'visible' : 'removed');
    }
  }, [consent]);

  useEffect(() => {
    if (variant === 'removed') {
      setConsent('true');
    }
  }, [setConsent, variant]);

  const hostVariants = {
    initial: {
      opacity: 0,
      x: '0%',
      transition: { type: 'spring', stiffness: 40 },
    },
    visible: {
      opacity: 1,
      x: '0%',
      transition: { type: 'spring', stiffness: 40 },
    },
    hidden: { opacity: 0, x: '-110%', mass: 0.5 },
    removed: {
      opacity: 0,
      display: 'none',
    },
  };

  return (
    <>
      <Host
        variants={hostVariants}
        initial="initial"
        animate={variant}
        onAnimationComplete={(s) => {
          if (s === 'hidden') {
            setVariant('removed');
          }
        }}>
        <Title>{isEn ? 'Cookies' : 'Vefkökur'}</Title>
        <Copy>
          {isEn
            ? `By clicking "Allow all" you agree to the use of cookies to increase the functionality of the website, analyze website usage and assist with marketing efforts.`
            : `Með því að smella á “Leyfa allar” samþykkir þú notkun á vefkökum til þess að auka virkni vefsins, greina vefnotkun og aðstoða við markaðssetningu.`}
        </Copy>
        <Button
          size="lg"
          variant="white"
          color="dark"
          onClick={() => setVariant('hidden')}>
          {isEn ? 'Allow all' : 'Leyfa allar'}
        </Button>
        <Button
          variant="outline"
          styles={{
            root: {
              border: 0,
              marginLeft: 16,
              color: '#fff',
            },
          }}
          onClick={() => setVariant('hidden')}>
          {isEn ? 'Close' : 'Loka'}
        </Button>
      </Host>
    </>
  );
};
