import React from 'react';

export function FormatNationalId({ value }: { value?: string | null }) {
  if (!value) {
    return null;
  }

  return (
    <span>
      {value.slice(0, 6)}-{value.slice(-4)}
    </span>
  );
}
