import { useRouter } from 'next/router';
import Script from 'next/script';
import { useEffect } from 'react';
import { config } from '../../config';
import { fbq, gist, gtag } from '../../lib/metrics';

export const ScriptsContainer = () => {
  const router = useRouter();
  const gistEnabled = router.query?.gist !== '0';

  useEffect(() => {
    // This pageview only triggers the first time (it's important for Pixel to have real information)
    fbq.pageview();

    const handleRouteChange = (url: string) => {
      gtag.pageview(url);
      fbq.pageview();
      gist.trackPageView();
    };

    router.events.on('routeChangeComplete', handleRouteChange);
    return () => {
      router.events.off('routeChangeComplete', handleRouteChange);
    };
  }, [router.events]);

  return (
    <>
      {/* Global Site Tag (gtag.js) - Google Analytics */}
      {gtag.script()}

      {/* Global Site Code Pixel - Facebook Pixel */}
      {fbq.script()}

      {/* Gist Code */}
      {config.gistAppId && gistEnabled && (
        <Script id="gist-init" strategy="lazyOnload">
          {`(function(d,h,w){var gist=w.gist=w.gist||[];gist.methods=['trackPageView','identify','track','setAppId'];gist.factory=function(t){return function(){var e=Array.prototype.slice.call(arguments);e.unshift(t);gist.push(e);return gist;}};for(var i=0;i<gist.methods.length;i++){var c=gist.methods[i];gist[c]=gist.factory(c)}s=d.createElement('script'),s.src="https://widget.getgist.com",s.async=!0,e=d.getElementsByTagName(h)[0],e.appendChild(s),s.addEventListener('load',function(e){},!1),gist.setAppId("${config.gistAppId}"),gist.trackPageView()})(document,'head',window);`}
        </Script>
      )}
    </>
  );
};
