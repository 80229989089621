import { Burger, Container } from '@mantine/core';
import { useRouter } from 'next/dist/client/router';
import Link from 'next/link';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

const Host = styled(Container)`
  width: 100%;
  min-height: 80px;
  display: flex;
  flex-direction: row;
  align-items: center;
  position: relative;
  z-index: 99;
`;

const Logo = styled.a`
  font-size: 40px;
  font-weight: 700;
  color: inherit;
  font-family: serif;
  text-decoration: none;

  margin-right: 16px;

  svg {
    width: 160px;
    height: 32px;
  }
`;

const Nav = styled.nav`
  margin-left: auto;
`;

interface HeaderProps {
  children: React.ReactNode;
  action?: React.ReactNode;
  logo: React.ReactNode;
}

const BurgerHost = styled.div`
  @media screen and (min-width: 768px) {
    display: none;
  }

  flex: 1;
  display: flex;
`;

const NavHost = styled.div<{ open: boolean }>`
  display: flex;
  flex: 1;
  @media screen and (max-width: 769px) {
    display: ${(props) => (props.open ? 'block' : 'none')};
    position: absolute;
    top: 80px;
    height: calc(100vh - 80px);
    left: 0px;
    right: 0px;
    background-color: white;
    color: black;
    z-index: 10;
    padding-top: 16px;
  }
`;

export const Header = ({ children, action, logo }: HeaderProps) => {
  const router = useRouter();
  const [open, setOpen] = useState(false);
  useEffect(() => {
    setOpen(false);
  }, [router.pathname]);
  return (
    <Host size="xl">
      <Link href="/" passHref>
        <Logo>{logo}</Logo>
      </Link>
      <BurgerHost>
        <Burger
          opened={open}
          onClick={() => setOpen((o) => !o)}
          size="sm"
          sx={{ marginLeft: 'auto' }}
        />
      </BurgerHost>
      <NavHost open={open}>
        <Nav>{children}</Nav>
      </NavHost>
    </Host>
  );
};
