// globalStyles.js
import { createGlobalStyle } from 'styled-components';

export const GlobalStyle = createGlobalStyle`
  body {
    overflow-y: scroll;
  }

  @media print {
    body {
      padding-right: 0 !important;
    }

    [aria-hidden]{
      display: none;
    }

    body .mantine-Paper-root {
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;

      width: 100%;
      height: 100%;
      border-radius: 0;
      max-width: 100%;
      box-shadow: none;
    }

    body .mantine-ActionIcon-root {
      display: none;
    }
  }

`;
