import {
  ActionIcon,
  Global,
  MantineProvider,
  MediaQuery,
  Menu,
  Text,
  useMantineTheme,
} from '@mantine/core';
import { showNotification, useNotifications } from '@mantine/notifications';
import * as Sentry from '@sentry/nextjs';
import { uniqBy } from 'lodash';
import { useRouter } from 'next/dist/client/router';
import Link from 'next/link';
import React, { useEffect } from 'react';
import { AiOutlineUser } from 'react-icons/ai';
import { BsGlobe } from 'react-icons/bs';
import { CgArrowsExchangeAltV } from 'react-icons/cg';
import { VscBell, VscLinkExternal, VscLock } from 'react-icons/vsc';
import { FormattedDate, useIntl } from 'react-intl';
import styled from 'styled-components';
import { FooterContainer } from '../../containers/footer/footer-container';
import {
  MarkNotificationReadDocument,
  NewNotificationDocument,
  useProfileQuery,
} from '../../graphql/generated';
import { client } from '../../lib/apollo-client';
import { authStore, useAuthStore } from '../../lib/auth-store';
import { emotionCache } from '../../lib/emotion-cache';
import { gist } from '../../lib/metrics';
import { uiStore } from '../../lib/ui-store';
import type { Options } from '../../pages/_app';
import { Gdpr } from '../gdpr/gdpr';
import { Header } from '../header/header';
import { MintumLogo } from '../logo/mintum-logo';
import { Navigation } from '../navigation/navigation';
import { NavigationItem } from '../navigation/navigation-item';
import { MainMenu } from './main-menu';
const Host = styled.div<{ bg: string; fg: string }>`
  position: relative;
  display: flex;
  flex-direction: column;

  min-height: 100vh;
  transition: 0.18s ease-in-out;
  transition-property: background-color, color;
  background-color: ${(props) => props.bg};
  color: ${(props) => props.fg};
`;

const Main = styled.main<{ bg: string; fg: string }>`
  transition: 0.18s ease-in-out;
  transition-property: background-color, color;
  background-color: ${(props) => props.bg};
  color: ${(props) => props.fg};
  display: flex;
  flex: 1;
  flex-direction: column;
`;

const NotificationsBadge = styled.div`
  width: 10px;
  height: 10px;
  border-radius: 8px;
  background-color: #ff0050;
  position: absolute;
  border: 2px solid white;
  top: 10px;
  right: 10px;
`;

interface LayoutProps {
  children: React.ReactNode;
  options: Options;
}

const Flex = styled.div`
  display: flex;
  flex-direction: column;
  border-top: 1px solid #ccc;
  margin-top: 20px;
  padding-top: 20px;
`;

const FlexRow = styled.div`
  display: flex;
`;

const Circle = styled.div<{ filled?: boolean }>`
  width: 10px;
  height: 10px;
  min-width: 10px;
  min-height: 10px;
  border-radius: 16px;
  background-color: ${(props) =>
    props.filled ? props.theme.colors.blue[6] : 'transparent'};
`;

const LinkComponent = ({
  href,
  children,
  ...rest
}: React.PropsWithChildren<{ href: string }>) => (
  <Link href={href} passHref>
    <a {...rest}>{children}</a>
  </Link>
);

export const Layout = ({ children, options }: LayoutProps) => {
  const auth = useAuthStore();

  const router = useRouter();
  const user = useProfileQuery({
    skip: !auth.loggedIn(),
  });

  const intl = useIntl();

  const theme = useMantineTheme();
  const notifications = useNotifications();

  useEffect(() => {
    uiStore.setState({ notifications });

    user.subscribeToMore({
      document: NewNotificationDocument,
      updateQuery(prev, { subscriptionData }: { subscriptionData: any }) {
        const me = {
          ...Object.assign({}, user.data?.me || {}, prev?.me || {}),
          id: prev?.me?.id || user.data?.me?.id || '',
          unreadNotificationsCount:
            (prev?.me?.unreadNotificationsCount ??
              user.data?.me?.unreadNotificationsCount ??
              0) + 1,
          notifications: {
            ...(prev?.me?.notifications || user.data?.me?.notifications || {}),
            edges: uniqBy(
              [
                {
                  cursor: '',
                  node: subscriptionData.data.newNotification,
                },
                ...(prev?.me?.notifications?.edges ||
                  user.data?.me?.notifications?.edges ||
                  []),
              ],
              (e) => e.node.id
            ),
          },
        };
        showNotification({
          autoClose: 5000,
          title: subscriptionData.data.newNotification.title,
          message: subscriptionData.data.newNotification.body,
        });
        return { me, priceTable: prev.priceTable };
      },
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (user.data) {
      Sentry.setUser({
        id: user.data.me.id,
        email: user.data.me.emailAddress || undefined,
      });
      gist.identify(user.data.me.id, {
        email: user.data.me.emailAddress || undefined,
        name: user?.data.me.fullName || undefined,
        nationalId: user?.data.me.nationalId || undefined,
        phoneNumber: user?.data.me.phoneNumber || undefined,
      });
      authStore.setState({
        user: {
          fullName: user?.data.me.fullName || undefined,
          emailAddress: user?.data.me.emailAddress || undefined,
          nationalId: user?.data.me.nationalId || undefined,
          priceTable: user?.data.priceTable || {},
        },
      });
    }
  }, [user.data]);

  useEffect(() => {
    if (authStore.persist.hasHydrated()) {
      if (options.authenticated && !auth.accessToken) {
        router.replace('/');
      }
    }
  }, [auth.accessToken, options.authenticated, router]);

  if (options.layout.empty) {
    return <>{children}</>;
  }

  const backgroundColor =
    theme.colorScheme === 'dark' ? theme.black : theme.white;

  const showAuthMenuItems = auth.loggedIn() && !options.layout.hideHeaderAuth;
  return (
    <Host
      bg={options.layout.background === 'dark' ? '#0D142A' : backgroundColor}
      fg={
        options.layout.background === 'dark' || theme.colorScheme === 'dark'
          ? theme.white
          : theme.black
      }>
      <Global
        styles={() => ({
          html: {
            backgroundColor:
              options.layout.background === 'dark'
                ? '#0D142A'
                : backgroundColor,
          },
        })}
      />
      <header>
        <MantineProvider
          emotionCache={emotionCache}
          theme={{
            ...theme,
            colorScheme:
              options.layout.background === 'dark' ? 'dark' : theme.colorScheme,
          }}>
          <Header logo={<MintumLogo />}>
            <Navigation>
              <Link
                href={!router.asPath.startsWith('/en') ? '/about' : '/en/about'}
                passHref>
                <NavigationItem active={!router.asPath.startsWith('/en/about')}>
                  {intl.formatMessage({
                    id: 'about-us',
                    defaultMessage: 'Um Okkur',
                  })}
                </NavigationItem>
              </Link>
              <Link href="https://support.mintum.is/" passHref>
                <NavigationItem target="_blank">
                  {intl.formatMessage({
                    id: 'help',
                    defaultMessage: 'Hjálp',
                  })}
                  <VscLinkExternal
                    style={{
                      width: 14,
                      marginBottom: -3,
                      marginLeft: 7,
                    }}
                  />
                </NavigationItem>
              </Link>

              <Link
                href={!router.asPath.startsWith('/en') ? '/en' : '/'}
                passHref>
                <NavigationItem>
                  {!router.asPath.startsWith('/en') ? 'English' : 'Íslenska'}
                  <BsGlobe
                    style={{
                      width: 14,
                      marginBottom: -3,
                      marginLeft: 7,
                    }}
                  />
                </NavigationItem>
              </Link>

              {/* <Link href="https://dex.mintum.is/" passHref>
                <NavigationItem target="_blank">
                  Kauphöll
                  <VscLinkExternal
                    style={{
                      width: 14,
                      marginBottom: -3,
                      marginLeft: 7,
                    }}
                  />
                </NavigationItem>
              </Link> */}
              {showAuthMenuItems ? (
                <Link href="/dashboard" passHref>
                  <NavigationItem>
                    {/* <MediaQuery largerThan="sm" styles={{ display: 'none' }}>
                      <span>Yfirlit</span>
                    </MediaQuery> */}
                    <MediaQuery smallerThan="sm" styles={{ display: 'none' }}>
                      <span>Mitt svæði</span>
                    </MediaQuery>
                  </NavigationItem>
                </Link>
              ) : null}
              {showAuthMenuItems ? (
                <Link href="/swap" passHref>
                  <NavigationItem highlight>
                    Swap
                    <CgArrowsExchangeAltV
                      style={{
                        color: 'rgb(155 48 255)',
                        transform: 'rotate(90deg)',
                        width: 22,
                        height: 22,
                        marginBottom: -5,
                      }}
                    />
                  </NavigationItem>
                </Link>
              ) : null}
              {showAuthMenuItems ? (
                <>
                  <MediaQuery
                    largerThan="sm"
                    styles={{ display: 'none !important' }}>
                    <Flex data-foo="123">
                      <Link href="/dashboard" passHref>
                        <NavigationItem
                          active={router.asPath.startsWith('/dashboard')}>
                          Mitt svæði
                        </NavigationItem>
                      </Link>
                      <Link href="/account" passHref>
                        <NavigationItem
                          active={router.asPath.startsWith('/account')}>
                          Stillingar
                        </NavigationItem>
                      </Link>
                      <Link href="/notifications" passHref>
                        <NavigationItem
                          active={router.asPath.startsWith('/notifications')}>
                          Tilkynningar
                        </NavigationItem>
                      </Link>
                      <NavigationItem
                        onClick={() =>
                          auth.actions.logout().then(() => router.push('/'))
                        }>
                        Útskráning
                      </NavigationItem>
                    </Flex>
                  </MediaQuery>
                  <MediaQuery
                    smallerThan="sm"
                    styles={{ display: 'none !important' }}>
                    <FlexRow>
                      <Menu
                        radius="md"
                        width={300}
                        position="bottom-end"
                        withArrow
                        styles={{
                          arrow: {
                            right: '16px !important',
                          },
                        }}>
                        <Menu.Target>
                          <ActionIcon radius="xl" sx={{ padding: 8 }} size="xl">
                            {(user?.data?.me?.unreadNotificationsCount || 0) >
                              0 && <NotificationsBadge />}
                            <VscBell style={{ width: 20, height: 20 }} />
                          </ActionIcon>
                        </Menu.Target>
                        <Menu.Dropdown>
                          <Menu.Label>Tilkynningar</Menu.Label>
                          {(user.data?.me.notifications.edges || []).map(
                            (edge) => (
                              <Menu.Item
                                key={edge.node.id}
                                onClick={() => {
                                  client.mutate({
                                    mutation: MarkNotificationReadDocument,
                                    variables: {
                                      id: edge.node.id,
                                    },
                                    update(cache) {
                                      cache.modify({
                                        id: cache.identify(user!.data!.me),
                                        fields: {
                                          unreadNotificationsCount(p = 0) {
                                            return p - 1;
                                          },
                                        },
                                      });
                                    },
                                  });
                                  router.push(
                                    `/notifications?id=${edge.node.id}`
                                  );
                                }}
                                rightSection={
                                  <Circle filled={!edge.node.readAt} />
                                }>
                                <Text
                                  size="xs"
                                  color="dimmed"
                                  dateTime={edge.node.createdAt}
                                  component="time">
                                  <FormattedDate value={edge.node.createdAt} />
                                </Text>
                                <Text size="sm">{edge.node.title}</Text>
                              </Menu.Item>
                            )
                          )}
                          {(user.data?.me.notifications.edges || []).length ===
                            0 && (
                            <Menu.Item disabled>Engar tilkynningar</Menu.Item>
                          )}
                        </Menu.Dropdown>
                      </Menu>
                      <Menu
                        radius="md"
                        position="bottom-end"
                        withArrow
                        width={220}
                        shadow="sm"
                        styles={{
                          // body: { padding: 0 },
                          arrow: { right: '16px !important' },
                          item: { borderRadius: 0 },
                          // itemInner: { flex: 1 },
                          itemLabel: {
                            flex: 1,
                            display: 'flex',
                            justifyContent: 'space-between',
                          },
                        }}>
                        <Menu.Target>
                          <ActionIcon radius="xl" sx={{ padding: 8 }} size="xl">
                            <AiOutlineUser
                              style={{
                                fontSize: '20px',
                                fontWeight: 500,
                                // color: '#3e3e3e',
                              }}
                            />
                          </ActionIcon>
                        </Menu.Target>
                        <Menu.Dropdown>
                          <MainMenu
                            fullName={user.data?.me?.fullName}
                            nationalId={user.data?.me?.nationalId}
                          />
                        </Menu.Dropdown>
                      </Menu>
                    </FlexRow>
                  </MediaQuery>
                </>
              ) : (
                !options.layout.hideHeaderAuth && (
                  <Link href="/login" passHref>
                    <NavigationItem>
                      {/* <Button
                      component="a"
                      // variant="gradient"
                      // gradient={{ from: 'blue', to: 'cyan' }}
                      size="lg"
                      radius="md"
                      rightIcon={<VscLock />}
                      sx={{ marginLeft: 16, paddingLeft: 20 }}> */}
                      {intl.formatMessage({
                        id: 'login',
                        defaultMessage: 'Innskráning',
                      })}
                      <VscLock
                        style={{
                          width: 16,
                          marginBottom: -3,
                          marginLeft: 7,
                        }}
                      />
                      {/* </Button> */}
                    </NavigationItem>
                  </Link>
                )
              )}
            </Navigation>
          </Header>
        </MantineProvider>
      </header>
      <Main
        bg={
          options.layout.background === 'dark'
            ? '#0D142A'
            : options.layout.background === 'elevated'
            ? theme.colorScheme === 'dark'
              ? '#1A1A1A'
              : '#F4F5F6'
            : backgroundColor
        }
        fg={
          options.layout.background === 'dark' || theme.colorScheme === 'dark'
            ? theme.white
            : theme.black
        }>
        {children}
        {!options.layout.hideHeaderAuth && <FooterContainer />}
        <Gdpr />
      </Main>
    </Host>
  );
};
