import type { NotificationsContextProps } from '@mantine/notifications/lib/types';
import { omit } from 'lodash';
import createHook from 'zustand';
import { persist } from 'zustand/middleware';
import create from 'zustand/vanilla';

type ColorScheme = 'light' | 'dark' | 'auto';
type Locale = 'en' | 'is';

interface UiStore {
  notifications: NotificationsContextProps;
  colorScheme: ColorScheme;
  locale: Locale;
  timezone: string;
  actions: {
    setColorScheme: (colorScheme: ColorScheme) => void;
    setLocale: (locale: Locale) => void;
    setTimezone: (timezone: string) => void;
  };
}

export const uiStore = create<UiStore>(
  persist(
    (set) => ({
      notifications: {} as NotificationsContextProps,
      colorScheme: 'light' as ColorScheme,
      locale: 'en' as Locale,
      timezone: 'Atlantic/Reykjavik',
      actions: {
        setColorScheme(colorScheme: ColorScheme) {
          set({ colorScheme });
        },
        setLocale(locale: Locale) {
          set({ locale });
        },
        setTimezone(timezone: string) {
          set({ timezone });
        },
      },
    }),
    {
      name: '@ui',
      partialize(state) {
        return omit(state, ['actions', 'notifications']);
      },
      version: 3,
    }
  )
);

export const useUiStore = createHook(uiStore);
