import type { MantineTheme } from '@mantine/core';

export const customTheme = {
  fontFamily: 'Sora, sans-serif',
  gutter: 32,
  segmentPadding: 180,
  segmentPaddingMobile: 60,
  headerHeight: 80,
  headerHeightMobile: 60,
  pageWidth: 1200,
  pageLimit: function pageLimit({
    theme,
  }: {
    theme: {
      pageWidth: number;
      customBreakpoints: Record<string, { gutter: number }>;
    };
  }) {
    return theme.pageWidth + theme.customBreakpoints.lg.gutter * 2;
  },
  customBreakpoints: {
    sm: { width: 576, gutter: 44 }, //  mobile
    md: { width: 768, gutter: 50 }, //  tablet
    lg: { width: 1024, gutter: 70 }, // desktop
  },
};

export type Theme = typeof customTheme & MantineTheme;
export type ThemeProps = { theme: Theme };
