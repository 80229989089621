import { gql } from '@apollo/client';
import { client } from './apollo-client';

const {
  functions: { coerceToArrayBuffer, b64encode },
  // eslint-disable-next-line @typescript-eslint/no-var-requires
} = require('fido2-helpers');

export async function assertSecurityKeyResult(response: string) {
  return (
    await client.mutate({
      mutation: gql`
        mutation securityKeyResult($response: String!) {
          securityKeyResult(type: "assertion", response: $response)
        }
      `,
      variables: {
        response,
      },
    })
  ).data.securityKeyResult;
}

export async function readSecurityKey(submit = true) {
  const res = await client.mutate({
    mutation: gql`
      mutation {
        securityKeyOptions(type: "assertion")
      }
    `,
  });
  const { securityKeyOptions } = res.data;
  const publicKey = {
    ...securityKeyOptions,
    allowCredentials: securityKeyOptions.allowCredentials.map(
      (credential: { id: string }) => ({
        ...credential,
        id: coerceToArrayBuffer(credential.id),
      })
    ),
    challenge: coerceToArrayBuffer(securityKeyOptions.challenge),
    user: {
      ...securityKeyOptions.user,
      id: coerceToArrayBuffer(securityKeyOptions.user.id),
    },
  };
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const result: any = await navigator.credentials.get({ publicKey });
  const response = JSON.stringify({
    id: result!.id,
    response: {
      clientDataJSON: b64encode(result.response.clientDataJSON),
      authenticatorData: b64encode(result.response.authenticatorData),
      signature: b64encode(result.response.signature),
    },
  });

  if (submit) {
    return await assertSecurityKeyResult(response);
  }

  return response;
}

export async function setupSecurityKey() {
  const res = await client.mutate({
    mutation: gql`
      mutation {
        securityKeyOptions(type: "attestation")
      }
    `,
  });
  const { securityKeyOptions } = res.data;
  const publicKey = {
    ...securityKeyOptions,
    excludeCredentials: securityKeyOptions.excludeCredentials.map(
      (credential: { id: string }) => ({
        ...credential,
        id: coerceToArrayBuffer(credential.id),
      })
    ),
    challenge: coerceToArrayBuffer(securityKeyOptions.challenge),
    user: {
      ...securityKeyOptions.user,
      id: coerceToArrayBuffer(securityKeyOptions.user.id),
    },
  };
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const result: any = await navigator.credentials.create({ publicKey });
  return JSON.stringify({
    id: result!.id,
    transports: result.response.getTransports?.(),
    response: {
      clientDataJSON: b64encode(result.response.clientDataJSON),
      attestationObject: b64encode(result.response.attestationObject),
    },
  });
}
