import { Menu } from '@mantine/core';
import Link from 'next/link';
import {
  AiOutlineBell,
  AiOutlineDashboard,
  AiOutlineLogout,
  AiOutlineSetting,
} from 'react-icons/ai';
import styled from 'styled-components';
import { FormatNationalId } from '../format/format-national-id';

const Name = styled.div`
  font-weight: 500;
  font-size: 21px;
  line-height: 25px;
  color: ${(props) =>
    props.theme.colorScheme === 'dark' ? '#fff' : '#00003c'};
  margin-bottom: 8px;
`;

const Subtitle = styled.div`
  font-weight: 500;
  font-size: 15px;
  line-height: 18px;
  color: ${({ theme }) => theme.colors.dark[2]};
`;

const Top = styled.div`
  padding: 16px;
`;

const NavItem = styled.div`
  button {
    padding: 12px 16px;
    font-weight: 400;
    font-size: 15px;
    line-height: 20px;
    display: flex;
    text-decoration: none;
    flex-direction: row;
    align-items: center;
    color: ${({ theme }) =>
      theme.colorScheme === 'dark' ? theme.colors.gray[0] : theme.black};
    border-top: 1px solid;
    border-top-color: ${({ theme }) =>
      theme.colorScheme === 'dark'
        ? theme.colors.gray[8]
        : theme.colors.gray[0]};
    transition: ease-in-out 110ms;
    transition-property: background-color, border-top-color, color;
    &:hover {
      background-color: ${({ theme }) =>
        theme.colorScheme === 'dark'
          ? theme.colors.gray[8]
          : theme.colors.gray[0]};
    }
  }
`;

const NavItems = styled.div`
  display: flex;
  flex-direction: column;
`;

const NavItemIcon = styled.div`
  width: 32px;
  svg {
    width: 32px;
    height: 20px;
  }
`;

const NavItemLabel = styled.div`
  flex: 1;
`;

export function MainMenu({ fullName, nationalId }: any) {
  const menuItems = [
    { label: 'Mitt svæði', href: '/dashboard', icon: <AiOutlineDashboard /> },
    { label: 'Stillingar', href: '/account', icon: <AiOutlineSetting /> },
    { label: 'Tilkynningar', href: '/notifications', icon: <AiOutlineBell /> },
    {
      label: 'Útskrá',
      href: '/logout',
      icon: <AiOutlineLogout />,
    },
  ];

  return (
    <>
      <Top>
        <Name>Hæ, {fullName}</Name>
        <Subtitle>
          <FormatNationalId value={nationalId} />
        </Subtitle>
      </Top>
      <NavItems>
        {menuItems.map((item, index) => (
          <NavItem key={index}>
            <Link href={item.href} passHref>
              <Menu.Item>
                <NavItemLabel>{item.label}</NavItemLabel>
                {item.icon && <NavItemIcon>{item.icon}</NavItemIcon>}
              </Menu.Item>
            </Link>
          </NavItem>
        ))}
      </NavItems>
    </>
  );
}

{
  /* <Menu.Label p="md">
<Title
  order={3}
  sx={{
    // fontWeight: 400,
    color: 'black',
  }}>
  {user?.data?.me?.fullName}
</Title>
<Text color="gray">
  <FormatNationalId
    value={user?.data?.me?.nationalId}
  />
</Text>
</Menu.Label>
<Divider />
<Menu.Item
icon={<AiOutlineDashboard />}
href="/dashboard"
component={LinkComponent}>
Yfirlit
</Menu.Item>
<Divider />
<Menu.Item
icon={<VscGear />}
href="/account"
component={LinkComponent}>
Stillingar
</Menu.Item>
<Menu.Item
icon={<VscBell />}
href="/notifications"
component={LinkComponent}>
Tilkynningar
</Menu.Item>
<Menu.Item
icon={<IoPowerOutline />}
onClick={() =>
  auth.actions.logout().then(() => router.push('/'))
}>
Útskráning
</Menu.Item> */
}
