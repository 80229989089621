import { Container } from '@mantine/core';
import Link from 'next/link';
import React from 'react';
import styled from 'styled-components';
import { device } from '../../styles/devices';

const Host = styled.footer`
  padding: 64px 0;
`;

const Row = styled.div`
  display: flex;
  justify-content: flex-end;
  flex-flow: column wrap;
  padding-top: 64px;

  @media ${device.tablet} {
    flex-flow: row wrap;
  }
`;

const Logo = styled.a`
  display: block;
  color: currentColor;
  svg {
    width: 260px;
    height: 52px;
  }
  flex: 1;
  margin-bottom: 60px;
`;

const Navs = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 60px;
  min-width: 55%;
  flex-direction: column;

  @media ${device.desktop} {
    flex-direction: row;
  }
`;

const Nav = styled.nav`
  display: flex;
  flex-flow: column nowrap;
  align-items: baseline;

  @media ${device.tablet} {
    margin-bottom: 0;
  }

  a {
    font-size: 18px;
    font-weight: 500;
    padding: 2px 0;
    padding-bottom: 4px;
    color: currentColor;
    text-decoration: none;
    position: relative;

    background-image: linear-gradient(
      90deg,
      currentColor 0%,
      currentColor 10%,
      #9623f2 25%,
      #3e45ff 50%,
      #3e45ff 100%
    );
    background-size: 1000%;
    background-position: 0%;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    transition: background-position ease-in-out 0ms;

    &:before {
      pointer-events: none;
      content: '';
      position: absolute;
      top: 100%;
      width: 100%;
      left: 0;
      height: 1.5px;
      transform: translateY(-1px);
      background-color: #9623f2;
      border-radius: 2px;
      background-position: 0%;
      background-size: 1000%;
      transition: ease-in-out;
      transition-property: background-color, opacity, transform;
      transition-duration: 330ms, 330ms, 110ms;
      transition-delay: 110ms, 0ms;
      opacity: 0;
    }

    &:hover {
      transition: background-position ease-in-out 990ms;
      background-position: 100%;

      &:before {
        transition-property: opacity, transform, background-color;
        transition-duration: 330ms, 110ms, 330ms;
        transition-delay: 110ms;
        background-color: #3e45ff;
        opacity: 1;
        transform: translateY(-3px);
      }
    }
  }
`;

const NavItems = styled.div`
  @media ${device.tablet} {
    display: flex;
    justify-items: space-between;

    margin-bottom: 60px;

    > nav {
      flex: 1;
      min-width: 180px;
    }
  }
`;

const Info = styled.div`
  margin-top: 60px;

  @media ${device.tablet} {
    margin-top: 0;
  }

  p {
    margin: 0;
    font-size: 18px;
    font-weight: 500;
    line-height: 1.6;
  }
`;

const SocialMedia = styled.div`
  display: flex;
  padding-top: 32px;
`;

interface FooterProps {
  logo: React.ReactNode;
  navs: React.ReactNode;
  info: React.ReactNode;
  socialMedia: React.ReactNode;
}

export const Footer = ({ logo, navs, info, socialMedia }: FooterProps) => {
  return (
    <Host>
      <Container size="xl" p="xl" sx={{ width: '100%' }}>
        <Row>
          <Link href="/" passHref>
            <Logo title="Fara á forsíðu Mintum">{logo}</Logo>
          </Link>

          <Navs>
            <NavItems>
              {React.Children.toArray(navs).map((nav, i) => {
                return <Nav key={i}>{nav}</Nav>;
              })}
            </NavItems>
            <Info>
              {info}
              <SocialMedia>{socialMedia}</SocialMedia>
            </Info>
          </Navs>
        </Row>
      </Container>
    </Host>
  );
};
